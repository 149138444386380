import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Button, Grid, IconButton, Modal, Pagination, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AuthService from "../../Components/auth/AuthService";
import { useNavigate } from "react-router-dom";
import {
  hideLoading,
  showLoading,
  useLoadingDispatch,
} from "../../context/loadingContext";
import { Table, Tooltip, Button as AntButton, DatePicker as AntDate } from "antd/es";
import { BellFilled, DeleteFilled, DownloadOutlined, EditFilled, PlusCircleFilled } from "@ant-design/icons";
import DatePicker from "react-date-picker";
import moment from "moment";
import { PcnStatusTypes, pcnActionTypes, pcnContraventionTypes, pcnFaultTypes, vehicleTypes } from "../../Components/common/constants";
import config from "./../../Components/config";
import Message from "../../Components/common/Message";
import { ActionDialog } from "../../Components/actionDialog/ActionDialog";
import CloseIcon from '@mui/icons-material/Close';
import { FormButton } from "../../Components/UiElements/UiElements";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  row: {
    cursor: "pointer",
  },
  tblTopDiv: {},
  tblsearch: {
    display: "flex",
    float: "left",
  },
  addIcon: {
    float: "right",
  },
  dateField: {
    width: "100%",
    marginRight: "10px",
    height: "40px",
    "& .react-date-picker__wrapper": {
      border: "thin solid #b6babf",
      borderRadius: '5px',
    }
  },
  antTable: {
    "& .ant-table-tbody > tr > td": {
      borderBottom: "1px solid #b7b9bd",
    },
  },
  antAddIcon: {
    "& .anticon-plus-circle:hover": {
      color: "#4096ff !important"
    }
  },
}));

const Auth = new AuthService();

export default function EquipmentList() {

  const classes = useStyles();
  const navigate = useNavigate();
  var layoutDispatch = useLoadingDispatch();
  const currentDate = new Date();
  const lastDateOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const [page, setPage] = React.useState(1);
  const [pageChange, setPageChange] = React.useState(false);
  const [limit] = React.useState(50);
  const [totalPages, setTotalPages] = React.useState(0);
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [allPCN, setAllPCN] = React.useState([]);
  const [drivers, setDrivers] = React.useState([]);
  const [allVehicle, setAllVehicle] = React.useState([]);
  const [allRoutes, setAllRoutes] = React.useState([]);
  const [startDate, setStartDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(lastDateOfMonth);
  const [filesOpen, setFilesOpen] = React.useState(false);
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [selectedRecordId, setSelectedRecordId] = React.useState(0);
  const [selectedDriver, setSelectedDriver] = useState("");
  const [selectedRoute, setSelectedRoute] = useState("");
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedFault, setselectedFault] = useState("");
  const [actionDialogProps, setActionDialogProps] = useState({
    message: "",
    type: "",
    isOpen: false,
  });

  const handleFilesOpen = (record) => {
    if (record?.pcn_file) {
      setSelectedRecordId(record.id);
      setSelectedFiles(JSON.parse(record?.pcn_file));
    }
    setFilesOpen(true);
  };

  const handleAppealOpen = (record) => {
    if (record?.pcn_appeal_file) {
      setSelectedRecordId(record.id);
      setSelectedFiles(JSON.parse(record?.pcn_appeal_file));
    }
    setFilesOpen(true);
  };

  const handleFilesClose = () => setFilesOpen(false);

  const handleRowClick = (id) => {
    navigate(`/pcn/${id}`)
  };

  const notifyPcn = async (record) => {
    const Pcn = JSON.parse(record.pcn_file)
    let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }

    if(!Pcn.length) {
      setType('error');
      setLoadMessage('Add Pcn files first before notifying' + str)
      return;
    }

    if(record.driver_phone && record.pcn_file.length) {
      showLoading(layoutDispatch);
      const messagePayload = {
        driver_name: record.driver_name,
        amount: '£' + record.amount,
        phone: record.driver_phone,
        pcn_file: `${config.tms_uploads_cdn}/${config.env}/pcns/${record.id}/${Pcn[0]}`,
        pcn_id: record.id
      };

      const emailPayload = {
        driver_name: record.driver_name,
        driver_id: record.driver_id,
        amount: '£' + record.amount,
        phone: record.driver_phone,
        pcn_file: `${config.tms_uploads_cdn}/${config.env}/pcns/${record.id}/${Pcn[0]}`,
        pcn_id: record.id,
        subject: `Magna TMS - PCN Reminder - ${record.driver_name}`,
        type: 1
      };
      try {
        const response = await fetch("https://promotions.api-magnafoodservice.co.uk/api/send-tms-pcn-received-notifications", {
          method: "POST",
          headers: {
            Authorization: "Bearer 5|YzMkDJ11kIbbabpv1atryJYb6CETFCbRJY8i9QOs",
            "Content-Type": "application/json"
          },
          body: JSON.stringify(messagePayload)
          });
          console.log('response', response)
          
          if(response.ok) {
            const ackData = {
              id: record.id,
              notified: 1
            }
            console.log('res')
            try {
              const notificationSentResponse = await Auth.fetch("/pcn/send-pcn-receive-notification", {
                method: "POST",
                body: JSON.stringify(ackData)
              });
              if(notificationSentResponse.ack) {
                setType('success')
                setLoadMessage(notificationSentResponse.message)
                setAllPCN((prevPCNs) =>
                 prevPCNs.map((pcn) =>
                pcn.id === record.id ? { ...pcn, notified: 1 } : pcn
              )
            );
              } else {
                setType('error')
                setLoadMessage(notificationSentResponse.message + str)
              }
            } catch (err) {
              console.log(err)
            }
          }
  
      } catch (error) {
        console.log(error)
      } finally {
        //Production url and token for email
        const EmailApiResponse = await fetch("https://tmsemail.api-magnafoodservice.co.uk/api/email/pcn", {
          method: "POST",
          headers: {
            Authorization: "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Im5vb3JAZ21haWwuY29tIiwidXNlcl9pZCI6MSwiaWF0IjoxNzI4NDE0NjIzLCJhdWQiOiJodHRwczovL3d3dy5tYWduYWZvb2RzZXJ2aWNlLmNvLnVrLyIsImlzcyI6Ik1hZ25hIEZvb2QgU2VydmljZSIsInN1YiI6Im5vb3JAZ21haWwuY29tIn0.fb--ERLcBgLhrec6Ed_rAR_uGiBWyFdLN-4fYjjdhjw411DODxyg058A7VVQuLAgD5A8OEa-9jOar0eOTqZk3WGnP8mhh_Tx0smCmiVNY73Odn0x0KZgVyx1yf4W6nEzAHJImmXwzbnPBTIVww7CG2a47GZ8M_XQxFJNhovOnIAEoIZdFz989QdyS_8UpTVIr1UV6nbpZ01ZNuHlU-ZBxJ9zPez1bD7Q9U8Gg59AeJsr3vp6XPDuWmW0C2G3Z3YO-5jT7e1S-lFNg7_8YTH5ju7RWlvdjOJ8lGIiQAVF4aRMXIJh3HHPYBdUWugj7Zb0ZF8F4rz8sR4zNgqEUXJtPQ",
            "Content-Type": "application/json"
          }, 
          body: JSON.stringify(emailPayload)
        });
        //local url
        // const EmailApiResponse = await fetch("http://localhost:7420/api/email/pcn", {
        //   method: "POST",
        //   headers: {
        //     Authorization: "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Im5vb3JAZ21haWwuY29tIiwidXNlcl9pZCI6MSwiaWF0IjoxNzI3ODgwNDE0LCJleHAiOjE3NTk0MTY0MTQsImF1ZCI6Imh0dHBzOi8vd3d3Lm1hZ25hZm9vZHNlcnZpY2UuY28udWsvIiwiaXNzIjoiTWFnbmEgRm9vZCBTZXJ2aWNlIiwic3ViIjoibm9vckBnbWFpbC5jb20ifQ.Bidb4jyOqnI4JdF9o0WpcEqiGQDpxv47KZ2mFyhFhLwj0LSui0ukFFH889J3tdVs3A-8ah-rk5026bfhEpneyUzkPl0IEE1iTBt4rdmXnUytXQRY38AsSiMv_v0S1gFL0JJtMh2Odzh3JpPbALaqEhCX0P2Kvhy9jNeczzWL8-rYw9b9WVgVN_mBd8AcdzgbzbeVOPd9hDL24im5QXwd6wIT9hme3e-lyBvW24bEp-ozxwaujVAZrtdh8BoSTTD2lyn0nKHc7iErGFjhFvtIw2pzvtnorNzfWJ2hB2BABQ0JOruxnxVks7NbNAMyBhchQhGV5af6ssDAZ4YaKUeNpw",
        //     "Content-Type": "application/json"
        //   }, 
        //   body: JSON.stringify(emailPayload)
        // });
        if (EmailApiResponse.ok === true) {


          setLoadMessage("Email sent ")
          setType('success')
        } else {
          setLoadMessage("Email Sendind Failure")
          setType('error')
        }
      hideLoading(layoutDispatch);
      }
    } else {
      setType('error');
      setLoadMessage('First add files and driver number in Pcn' + str)
    }
    
  }

  const ShowActionDialog = (
    message,
    type,
    isOpen,
    OnOkCallback,
    OnCancellCallback
  ) => {
    setActionDialogProps((prevState) => ({
      ...prevState,
      message: message,
      type: type,
      isOpen: isOpen,
      onOk: OnOkCallback,
      onCancel: OnCancellCallback,
    }));
  };

  const handleDelete = (id) => {
    ShowActionDialog(
      "Are you sure to want to delete this record?",
      "confirm",
      true,
      function () {
        const params = {
          method: "DELETE",
          body: JSON.stringify({
            id: id,
          }),
        };
        Auth.fetch(`/pcn/pcn`, params)
          .then((res) => {
            if (res?.ack) {
              setType("info");
              setLoadMessage(res.message);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            } else {
              setLoadMessage("error");
              setLoadMessage(res.status);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    );
  };

  const PCNColumns = [
    {
      title: "Status",
      render(record) {
        const statusType = PcnStatusTypes.find((obj) => obj.id == record?.status);
        const background = statusType ? statusType.background : '';
        return {
          props: {
            style: { background: background }
          },
          children: <span>
            { record?.status
              ? PcnStatusTypes.filter((obj) => obj.id == record.status)[0]?.title
              : ""}
          </span>
        };
      },
      width: 180
    },
    {
      title: 'Received Date',
      render: (record) => {
        return record.pcn_rec_date
          ? moment(record.pcn_rec_date).format("YYYY-MM-DD")
          : ""
      },
      width: 130
    },
    {
      title: ' PCN Date',
      render: (record) => {
        return record.pcn_date
          ? moment(record.pcn_date).format("YYYY-MM-DD H:mm")
          : ""
      },
      width: 200
    },
    {
      title: "Truck Reg.",
      dataIndex: 'registration_number',
      key: 'registration_number',
      width: 160
    },
    {
      title: 'Route',
      dataIndex: "route",
      key: "route",
      width: 150
    },
    {
      title: 'Driver',
      dataIndex: "driver_name",
      key: "driver_name",
      width: 230
    },
    {
      title: 'Contravention',
      render: (record) => {
        return record.contravention
          ? pcnContraventionTypes.filter((obj) => obj.id == record.contravention)[0]?.title
          : ""
      },
      width: 400
    },
    {
      title: 'Actions',
      render: (record) => {
        return record.action
          ? pcnActionTypes.filter((obj) => obj.id == record.action)[0].title
          : ""
      },
      width: 200
    },
    {
      title: 'Fault',
      render: (record) => {
        return record.fault
          ? pcnFaultTypes.filter((obj) => obj.id == record.fault)[0]?.title
          : ""
      },
    },
    {
      title: 'Fine',
      render: (record) => {
        return record.amount
          ? "£" +
          Number(record.amount).toLocaleString(undefined, {
            minimumFractionDigits: 2,
          })
          : ""
      }
    },
    {
      title: "PCN File",
      render: (record) => {
        return JSON.parse(record.pcn_file).length > 0 ? (
          <Button
            onClick={() => {
              handleFilesOpen(record);
            }}
          >
            {JSON.parse(record.pcn_file).length + "-Files"}
          </Button>
        ) : (
          <Button
            disabled
          >
            {JSON.parse(record.pcn_file).length + "-Files"}
          </Button>
        )
      },
      width: 100
    },
    {
      title: "Add. Documents",
      render: (record) => {
        return JSON.parse(record.pcn_appeal_file).length > 0 ? (
          <Button
            onClick={() => {
              handleAppealOpen(record);
            }}
          >
            {JSON.parse(record.pcn_appeal_file).length + "-Files"}
          </Button>
        ) : (
          <Button
            disabled
          >
            {JSON.parse(record.pcn_appeal_file).length + "-Files"}
          </Button>
        )
      },
      width: 100
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: '',
      width: '200px',
      render(record) {
        console.log('record', record )
        return (
          <div style={{display: 'flex', justifyContent: 'center'}} onClick={(e) => e.stopPropagation()}>
            {
              (record.notified < 1 && record.fault != 1) && 
              <Tooltip placement="leftTop" title={`Notify Driver at ${record.driver_phone}`}> <BellFilled onClick={() => notifyPcn(record)}  style={{ fontSize: '25px', cursor: "pointer", color: "#006cb8" }} /></Tooltip>
            }
            <EditFilled onClick={() => handleRowClick(record.id)} style={{ fontSize: '25px', cursor: "pointer", color: "#001529" }} />
            {
              !record.closed && <DeleteFilled onClick={() => handleDelete(record.id)} style={{ fontSize: '25px', cursor: "pointer", marginRight: "5px", color: "#cd1437" }} />
            }
            
            
          </div>
        );
      },
    }
  ];

  const search = (download) => {
    let search = {};
    if (startDate) search.start_date = moment(startDate).format("YYYY-MM-DD");
    if (endDate) search.end_date = moment(endDate).format("YYYY-MM-DD");
    if (selectedDriver) search.driver_id = selectedDriver.id;
    if (selectedVehicle) search.vehicle_id = selectedVehicle.id;
    if (selectedRoute) search.route_id = selectedRoute.id;
    if (selectedFault) search.fault = selectedFault.id;
    if (selectedStatus) search.status = selectedStatus.id;
    if (page && pageChange) search.page = page;

    if (download) search.download = 1;

    showLoading(layoutDispatch);
    Auth.fetch("/pcn/pcns", {
      method: "POST",
      body: JSON.stringify(search),
    }).then((res) => {
      hideLoading(layoutDispatch);
      if (res?.ack) {
        if (download) {
          window.open(res.file_url, "_blank", "noreferrer");
        }
        else {
          setAllPCN(res?.pcns);
          setTotalPages(Math.ceil(res.total_count / limit));
        }
      } else {
        setAllPCN([]);
      }
    });
  };

  const getAllDrivers = async () => {
    showLoading(layoutDispatch);
    try {
      const response = await Auth.fetch(`/driver/drivers`);
      if (response) {
        hideLoading(layoutDispatch);
        if (response?.ack) {
          setDrivers(response.drivers);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  const getAllRoutes = async () => {
    showLoading(layoutDispatch);
    try {
      const response = await Auth.post(`/route/all-routes`);
      if (response) {
        hideLoading(layoutDispatch);
        if (response?.ack) {
          setAllRoutes(response.routes);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  const getAllVehicles = async () => {
    showLoading(layoutDispatch);
    try {
      const response = await Auth.fetch(`/vehicle/vehicles`);
      if (response) {
        hideLoading(layoutDispatch);
        if (response?.ack) {
          setAllVehicle(response.vehicles);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleChange = (event, value) => {
    setPage(value);
    setPageChange(true)
  };

  React.useEffect(() => {
    getAllDrivers();
    getAllVehicles();
    getAllRoutes();

    // const firstDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    // setStartDate(firstDayOfMonth);

    // const lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    // setEndDate(lastDayOfMonth);

  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => search(), 1000);
    return () => clearTimeout(timeOutId);
  }, [
    startDate,
    endDate,
    selectedDriver,
    selectedVehicle,
    selectedFault,
    selectedStatus,
    selectedRoute,
    page
  ]);

  const [screenWidth, setScreenWidth] = React.useState("");

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  React.useEffect(() => {
    setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const updateFilters = (newValues) => {
    const existingFiltersString = localStorage.getItem('PcnFilters');
    const existingFilters = existingFiltersString ? JSON.parse(existingFiltersString) : {};
  
    Object.assign(existingFilters, newValues);

    existingFilters.timestamp = new Date().getTime();
  
    const updatedFiltersString = JSON.stringify(existingFilters);
  
    localStorage.setItem('PcnFilters', updatedFiltersString);
  };

  const getFiltersOrDefault = () => {
    const existingFiltersString = localStorage.getItem('PcnFilters');
    const existingFilters = existingFiltersString ? JSON.parse(existingFiltersString) : {};
    const currentTime = new Date().getTime();
    const expirationTime = 30 * 60 * 1000;
    

    if (existingFilters.timestamp && currentTime - existingFilters.timestamp < expirationTime) {
      if (!existingFilters.driver) {
        existingFilters.driver = '';
      }
  
      if (!existingFilters.vehicle) {
        existingFilters.vehicle = '';
      }

      if (!existingFilters.route) {
        existingFilters.route = '';
      }
  
      if (!existingFilters.fault) {
        existingFilters.fault = '';
      }
  
      if (!existingFilters.status) {
        existingFilters.status = '';
      }

      return existingFilters;
    } else {
      localStorage.removeItem('PcnFilters');
      return {};
    }
  };

  useEffect(() => {
    const initialState = getFiltersOrDefault();
    setSelectedDriver(initialState?.driver);
    setSelectedVehicle(initialState?.vehicle);
    setSelectedRoute(initialState?.route);
    setSelectedStatus(initialState?.status);
    setselectedFault(initialState?.fault);
    if (!initialState?.startDate) {
      const firstDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      setStartDate(firstDayOfMonth);
      initialState.startDate = firstDayOfMonth

    } else {
      setStartDate(initialState.startDate);
    }

    if (!initialState?.endDate) {
      const lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
      setEndDate(lastDayOfMonth);
      initialState.endDate = lastDateOfMonth

    } else {
      setEndDate(initialState.endDate);
    }
    initialState.timestamp = new Date().getTime();
    updateFilters( initialState )

  }, []);

  return (
    <div>
            <h2 style={{marginBottom: '10px', marginTop: 0}}>PCNs</h2>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid item xs={8}>
        </Grid>
        <Grid item xs={4}>
        <AntButton
            style={{ float: "right" }}
            type="primary"
            size='large'
            icon={<DownloadOutlined />}
            onClick={() => { search(1) }}
          >
            Download
          </AntButton>
        </Grid>
      </Grid>
      <Grid container rowSpacing={2} columnSpacing={1.5}>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1.5}>
          <label htmlFor="pcnDate">Start Date:</label>
          {/* <DatePicker
            format={"dd-MM-yyyy"}
            className={classes.dateField}
            onChange={(date) => {
              setStartDate(date)
              updateFilters({ startDate: date })
              setPageChange(false)
              setPage(1)
            }}
            value={startDate}
          /> */}
          <AntDate
               format={'YYYY-MM-DD'}
               style={{ width: '100%'}}
               size="large"
               value={startDate ? dayjs(startDate) : null}
               onChange={(_, dateStr) => {
                setStartDate(dateStr);
                updateFilters({ startDate: dateStr })
                setPageChange(false)
                setPage(1)
              }}
              placeholder="Select Start Date" 
               />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1.5}>
          <label htmlFor="pcnDate">End Date:</label>
          {/* <DatePicker
            format={"dd-MM-yyyy"}
            className={classes.dateField}
            onChange={(date) => {
              setEndDate(date)
              updateFilters({ endDate: date })
              setPageChange(false)
              setPage(1)

            }}
            value={endDate}
          /> */}
          <AntDate
               format={'YYYY-MM-DD'}
               style={{ width: '100%'}}
               size="large"
               value={endDate ? dayjs(endDate) : null}
               onChange={(_, dateStr) => {
                setEndDate(dateStr);
                updateFilters({ endDate: dateStr })
                setPageChange(false)
                setPage(1)
              }}
              placeholder="Select End Date" 
               />
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={2} xl={1.5}>
          <label htmlFor="route">Routes</label>
          <Autocomplete
            id="route"
            size="small"
            options={allRoutes}
            getOptionLabel={(option) => option.title ? `${option.title} (${option.code})` : ''}
            value={selectedRoute}
            onChange={(e, values) => {
              setSelectedRoute(values);
              updateFilters({ route: values })
              setPageChange(false)
              setPage(1)

            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Route"
              />
            )}
          />
        </Grid>
        
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1.5}>
          <label htmlFor="route">Vehicles</label>
          <Autocomplete
            id="vehicles"
            size="small"
            options={allVehicle}
            getOptionLabel={(option) => option.registration_number ? option.registration_number : ''}
            value={selectedVehicle}
            onChange={(e, values) => {
              setSelectedVehicle(values);
              updateFilters({ vehicle: values })
              setPageChange(false)
              setPage(1)

            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Vehicle"
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={2} xl={1.5}>
          <label htmlFor="Driver">Driver</label>
          <Autocomplete
            id="Driver-type"
            size="small"
            options={drivers}
            getOptionLabel={(option) => option.driver_name ? option.driver_name : ''}
            value={selectedDriver}
            onChange={(e, values) => {
              setSelectedDriver(values);
              updateFilters({ driver: values })
              setPageChange(false)
              setPage(1)

            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Driver"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1.5}>
          <label htmlFor="Fault">Fault</label>
          <Autocomplete
            id="fault-type"
            size="small"
            options={pcnFaultTypes}
            getOptionLabel={(option) => option.title ? option.title : ''}
            value={selectedFault}
            onChange={(e, values) => {
              setselectedFault(values);
              updateFilters({ fault: values })
              setPageChange(false)
              setPage(1)

            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Fault"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1.5}>
          <label htmlFor="Status">Status</label>
          <Autocomplete
            id="Status-type"
            size="small"
            options={PcnStatusTypes}
            getOptionLabel={(option) => option.title ? option.title : ''}
            value={selectedStatus}
            onChange={(e, values) => {
              setSelectedStatus(values);
              updateFilters({ status: values })
              setPageChange(false)
              setPage(1)

            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Status"
              />
            )}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={3} lg={3} xl={1}>
          <FormButton
            sx={{ height: "56px", marginTop: "22px", width: "150px" }}
            type="print"
            onClick={() => { search(1) }}
          >
            Download
          </FormButton>
        </Grid> */}
      </Grid>



      <Modal
        open={filesOpen}
        onClose={handleFilesClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          overflowY: "auto",
          maxHeight: "90vh",
          transform: "translate(-50%, -50%)",
          width: screenWidth < 610 ? "90%" : 600,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleFilesClose}
            aria-label="close"
            sx={{ position: 'absolute', top: 0, right: 0, marginRight: '5px' }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Download the files below
          </Typography>
          {selectedFiles.length > 0 &&
            selectedFiles.map((file, idx) => {
              const lastHyphenIndex = file.lastIndexOf('-')
              const dotBeforeExtensionIndex = file.lastIndexOf('.');
              const newFileName = file.slice(0, lastHyphenIndex) + file.slice(dotBeforeExtensionIndex);
              return (
                <li key={idx}>
                  <a
                    href={`${config.tms_uploads_cdn}/${config.env}/pcns/${selectedRecordId}/${file}`}
                    target="_blank"
                  >
                    {newFileName}
                  </a>
                </li>
              );
            })}
        </Box>
      </Modal>
      <br />
      <Grid item xs={4}>
        <div className={classes.antAddIcon}>
          <PlusCircleFilled style={{ fontSize: "30px",color: "#1677FF", float: "right", marginBottom: "10px", }} onClick={() => navigate("/pcn/new")} />
        </div>
      </Grid>
      <div className={classes.antTable}>
        <Table
          columns={PCNColumns}
          style={{ overflowX: "auto" }}
          pagination={false}
          dataSource={allPCN}
          footer={() => `List of PCNs - Total Records: ${allPCN.length === 0 ? "0" : allPCN.length}`}
        />
      </div>

      <Message type={type} msg={loadMessage} />
      <div style={{ float: "right", margin: "10px" }}>
        <Pagination
          page={page}
          count={totalPages}
          variant="outlined"
          color="primary"
          onChange={handleChange}
        />
      </div>
      <ActionDialog
        message={actionDialogProps.message}
        type={actionDialogProps.type}
        isOpen={actionDialogProps.isOpen}
        onClose={actionDialogProps.onClose}
        onSubmit={() => {
          actionDialogProps.onOk();
        }}
        onCancel={() =>
          setActionDialogProps((prevState) => ({
            ...prevState,
            isOpen: false,
          }))
        }
      />
    </div>
  );
}
