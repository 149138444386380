import React, { useEffect, useLayoutEffect, useState } from "react";
import { Autocomplete, Box, Grid, IconButton, Modal, Pagination, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AuthService from "../../Components/auth/AuthService";
import { useNavigate } from "react-router-dom";
import {
  hideLoading,
  showLoading,
  useLoadingDispatch,
} from "../../context/loadingContext";
import { Button, Table, DatePicker as AntDate } from "antd/es";
import { DeleteFilled, EditFilled, PlusCircleFilled } from "@ant-design/icons";
import DatePicker from "react-date-picker";
import moment from "moment";
import { discountStatus } from "../../Components/common/constants";
import Message from "../../Components/common/Message";
import DiscountModal from "../../Components/Modals/DiscountModal";
import config from "../../Components/config";
import dayjs from "dayjs";
const useStyles = makeStyles((theme) => ({
  row: {
    cursor: "pointer",
  },
  tblTopDiv: {},
  tblsearch: {
    display: "flex",
    float: "left",
  },
  addIcon: {
    float: "right",
  },
  dateField: {
    width: "100%",
    marginRight: "10px",
    height: "40px",
    "& .react-date-picker__wrapper": {
      border: "thin solid #b6babf",
      borderRadius: '5px',
    }
  },
  antTable: {
    "& .ant-table-tbody > tr > td": {
      borderBottom: "1px solid #b7b9bd",
    },
  },
  antAddIcon: {
    "& .anticon-plus-circle:hover": {
      color: "#4096ff !important"
    }
  },
}));

const Auth = new AuthService();

export default function DiscountReport() {

  const classes = useStyles();
  const navigate = useNavigate();
  var layoutDispatch = useLoadingDispatch();
  const currentDate = new Date();
  const [page, setPage] = React.useState(1);
  const [pageChange, setPageChange] = React.useState(false);
  const [limit] = React.useState(50);
  const [totalPages, setTotalPages] = React.useState(0);
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [allDiscounts, setAllDiscounts] = React.useState([]);
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [selectedStatus, setSelectedStatus] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState('');
  const [paymentAuthorizer, setPaymentAuthorizer] = React.useState([]);



  const getPaymentAuthorizer = async () => {
    setIsLoading(true);
    
    try {
      const res = await Auth.fetch("/driverapp/get-payment-authorizers");
      if (res?.ack) {
        setPaymentAuthorizer(res?.payment_authorizers)
      } else {
        setType('error')
        setLoadMessage(res?.message)
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };



  const accidentColumns = [
    {
      title: 'Invoice',
      dataIndex: "invoice_no",
      render: (text, record) => {
        return <span><a href={`${config.netsuite_url}/app/accounting/transactions/custinvc.nl?id=${record.inv_internal_id}`} target="_blank">{record.invoice_no}</a></span>;
      },
    },
    {
      title: 'Customer Code',
      dataIndex: "customer_name",
      render: (text, record) => {
        return <span><a href={`${config.netsuite_url}/app/common/entity/custjob.nl?id=${record.customer_internal_id}`} target="_blank">{record.customer_name}</a></span>;
      },
    },
    {
      title: 'Customer',
      key: "customer_project_id",
      dataIndex: "customer_project_id"
    },
    {
      title: 'Driver',
      key: "driver",
      dataIndex: "driver"
    },
    {
      title: 'Authorizer',
      render(record) {
        let statusText;
        if (record?.type == 1) {
          const authorizer = record.delivery_discount_auth_by 
            ? paymentAuthorizer.find((obj) => obj.id == record.delivery_discount_auth_by) 
            : null;
          statusText = authorizer 
            ? `${authorizer.first_name} ${authorizer.last_name}` 
            : "";
        } else {
          const authorizer = record.payment_auth_by 
            ? paymentAuthorizer.find((obj) => obj.id == record.payment_auth_by) 
            : null;
          statusText = authorizer 
            ? `${authorizer.first_name} ${authorizer.last_name}` 
            : "";
        }
  
        return {
          props: {},
          children: <>{statusText}</>,
        };
      }
    },
    {
      title: 'Driver Notes',
      key: "notes",
      dataIndex: "notes"
    },
    {
      title: 'Authorizer Notes',
      render(record) {
        let statusText;
        if (record?.type == 1) {
          statusText = record?.delivery_discount_req_notes ? record?.delivery_discount_req_notes : ""
        } else {
          statusText = record?.payment_req_notes ? record?.payment_req_notes : ""
        }

        return {
          props: {

          },
          children: <>{statusText}</>,
        };
      }
    },
    {
      title: 'Reason',
      render(record) {
        let statusText;
        if (record?.type == 1) {
          statusText = "Delivery Discount"
        } else {
          statusText = "Payment Authorization"
        }

        return {
          props: {

          },
          children: <>{statusText}</>,
        };
      }
    },
    {
        title: 'Request time',
        // render: (text, record) => {
        //   return record?.type == 1 ? (record.delivery_discount_req_sent ? moment(record.delivery_discount_req_sent).format("YYYY-MM-DD HH:mm:ss") : "") : (record.payment_req_sent ? moment(record.payment_req_sent).format("YYYY-MM-DD HH:MM:SS") : "");
        // },
        render(record) {
          let statusText;
          if (record?.type == 1) {
            statusText = record.delivery_discount_req_sent ? moment(record.delivery_discount_req_sent).format("YYYY-MM-DD HH:mm:ss") : ""
          } else {
            statusText = record.payment_req_sent ? moment(record.payment_req_sent).format("YYYY-MM-DD HH:mm:ss") : ""
          }
  
          return {
            props: {
  
            },
            children: <>{statusText}</>,
          };
        }
      },
      {
        title: 'Response time',
        render(record) {
          let statusText;
          if (record?.type == 1) {
            statusText = record.delivery_discount_req_responded ? moment(record.delivery_discount_req_responded).format("YYYY-MM-DD HH:mm:ss") : ""
          } else {
            statusText = record.payment_req_responded ? moment(record.payment_req_responded).format("YYYY-MM-DD HH:mm:ss") : ""
          }
  
          return {
            props: {
  
            },
            children: <>{statusText}</>,
          };
        }
      },
    {
      title: "Status",
      render(record) {
        let statusText;
        let backgroundColor;
        let status;
        if (record.type == 1) {
          status = record.delivery_discount_req_status
        } else {
          status = record.payment_req_status
        }

        switch (status) {
          case 1:
            statusText = "Pending";
            backgroundColor = "#faad14";
            break;
          case 2:
            statusText = "Approved";
            backgroundColor = "#80cb80";
            break;
          default:
            statusText = "Rejected";
            backgroundColor = "red";
            break;
        }

        return {
          props: {
            style: {
              background: backgroundColor,
            },
          },
          children: <>{statusText}</>,
        };
      },
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      dataIndex: '',
      render(record) {
        console.log('record', record)
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }} onClick={(e) => e.stopPropagation()}>
            {/* {
            ((record?.delivery_discount_req_status === 1 && record?.type == 1) || (record?.payment_req_status === 1 && record?.type ==2)) ? (
                <EditFilled style={{ fontSize: '22px'}}  onClick={()=>handleSelect(record)}/>
            ) : (
                <EditFilled style={{ cursor: "no-drop"}} disabled/>

            )
            } */}
            <EditFilled style={{ fontSize: '22px'}}  onClick={()=>handleSelect(record)}/>
          </div>
        );
      },
    }
  ];

  const handleSelect = (record) => {
    navigate(`/discounts/${record?.internal_id}/${Number(record?.type)}`)
    // setSelectedRecord(record)
    // setIsModalOpen(true);

  }

  const search = () => {
    let search = {};
    if (startDate) search.date = moment(startDate).format("YYYY-MM-DD");
    // if (endDate) search.end_date = moment(endDate).format("YYYY-MM-DD");
    if (selectedStatus) search.status = selectedStatus.id;
    if (page && pageChange) search.page = page;

    // showLoading(layoutDispatch);
    setIsLoading(true)
    Auth.fetch("/route/get-payment-authorization-status", {
      method: "POST",
      body: JSON.stringify(search),
    }).then((res) => {
    //   hideLoading(layoutDispatch);
    setIsLoading(false)
      if (res?.ack) {
        setAllDiscounts(res?.payment_auth_statuses);
        setTotalPages(Math.ceil(res.total_count / limit));
      } else {
        setAllDiscounts([]);
      }
    });
  };

  const handleChange = (event, value) => {
    setPage(value);
    setPageChange(true)
  };

  useEffect(() => {
    const searchTimeout = setTimeout(() => search(), 1000);
    const intervalId = setInterval(() => search(), 30000);

    return () => {
        clearTimeout(searchTimeout);
        clearInterval(intervalId);
    };
}, [startDate, selectedStatus, page]);

useLayoutEffect(()=> {
  getPaymentAuthorizer()
},[])

  

  const [screenWidth, setScreenWidth] = React.useState("");

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  React.useEffect(() => {
    setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const updateFilters = (newValues) => {
    const existingFiltersString = localStorage.getItem('DiscountFilters');
    const existingFilters = existingFiltersString ? JSON.parse(existingFiltersString) : {};
    Object.assign(existingFilters, newValues);
    existingFilters.timestamp = new Date().getTime();
    const updatedFiltersString = JSON.stringify(existingFilters);
    localStorage.setItem('DiscountFilters', updatedFiltersString);
  };

  const getFiltersOrDefault = () => {
    const existingFiltersString = localStorage.getItem('DiscountFilters');
    const existingFilters = existingFiltersString ? JSON.parse(existingFiltersString) : {};
    const currentTime = new Date().getTime();
    const expirationTime = 30 * 60 * 1000;
    if (existingFilters.timestamp && currentTime - existingFilters.timestamp < expirationTime) {

      if (!existingFilters.status) {
        existingFilters.status = '';
      }
      return existingFilters;
    } else {
      localStorage.removeItem('DiscountFilters');
      return {};
    }
  };

  useEffect(() => {
    const initialState = getFiltersOrDefault();
    if (!initialState?.status) {
      setSelectedStatus({ id: 1, title: "Pending" });
    } else {
      setSelectedStatus(initialState?.status);
    }
    if (!initialState?.startDate) {
      setStartDate(currentDate);
      initialState.startDate = currentDate

    } else {
      setStartDate(initialState.startDate);
    }

    // if (!initialState?.endDate) {
    //   setEndDate(currentDate);
    //   initialState.endDate = currentDate

    // } else {
    //   setEndDate(initialState.endDate);
    // }
    initialState.timestamp = new Date().getTime();
    updateFilters(initialState)
  }, []);

  return (
    <div>
      <h2>Disounts</h2>
      <Grid container rowSpacing={2} columnSpacing={1.5}>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1.5}>
          <label htmlFor="pcnDate">Route Date:</label>
          {/* <DatePicker
            format={"dd-MM-yyyy"}
            className={classes.dateField}
            onChange={(date) => {
              setStartDate(date)
              updateFilters({ startDate: date })
              setPageChange(false)
              setPage(1)
            }}
            value={startDate}
          /> */}
          <AntDate
               format={'YYYY-MM-DD'}
               style={{ width: '100%'}}
               size="large"
               value={startDate ? dayjs(startDate) : null}
               onChange={(_, dateStr) => {
                setStartDate(dateStr);
                updateFilters({ startDate: dateStr })
                setPageChange(false)
              setPage(1)
              }}
              placeholder="Select Start Date" 
               />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={3} lg={2} xl={1.5}>
          <label htmlFor="pcnDate">End Date:</label>
          <DatePicker
            format={"dd-MM-yyyy"}
            className={classes.dateField}
            onChange={(date) => {
              setEndDate(date)
              updateFilters({ endDate: date })
              setPageChange(false)
              setPage(1)
            }}
            value={endDate}
          />
        </Grid> */}
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1.5}>
          <label htmlFor="Status">Status</label>
          <Autocomplete
            size="small"
            id="Status-type"
            options={discountStatus}
            getOptionLabel={(option) => option.title ? option.title : ''}
            value={selectedStatus}
            onChange={(e, values) => {
              setSelectedStatus(values);
              updateFilters({ status: values })
              setPageChange(false)
              setPage(1)

            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Status"
              />
            )}
          />
        </Grid>
      </Grid>

      {/* <Grid item xs={4}>
        <div className={classes.antAddIcon}>
          <PlusCircleFilled style={{ fontSize: "30px", color: "#1677FF", float: "right", marginBottom: "10px", }} onClick={() => navigate("/accident/new")} />
        </div>
      </Grid> */}
      <div className={classes.antTable}>
        <Table
          columns={accidentColumns}
          style={{ overflowX: "auto" }}
          pagination={false}
          bordered
          dataSource={allDiscounts}
          loading={isLoading}
          footer={() => `List of Discounts - Total Records: ${allDiscounts?.length === 0 ? "0" : allDiscounts?.length}`}
        />
      </div>

      <div style={{ float: "right", margin: "10px" }}>
        <Pagination
          page={page}
          count={totalPages}
          variant="outlined"
          color="primary"
          onChange={handleChange}
        />
      </div>
      {/* <DiscountModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} selectedRecord={selectedRecord} setLoadMessage={setLoadMessage} setType={setType} search={search} /> */}
      <Message type={type} msg={loadMessage} />
    </div>
  );
}
