import React, { useState } from "react";
import jsPDF from "jspdf";
import { renderToString } from "react-dom/server";
import AuthService from "../../Components/auth/AuthService";
import DatePicker from "react-date-picker";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { Box, Button, Grid, rgbToHex, Typography, Autocomplete, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { selectClasses } from "@mui/material/Select";
import Message from "../../Components/common/Message";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import _, { times } from "lodash";
import 'jspdf-autotable';

import { vehicleTypes } from "./../../Components/common/constants";
import { Print } from "@mui/icons-material";
import { FormButton } from "../../Components/UiElements/UiElements";
import {
  useLoadingDispatch,
  showLoading,
  hideLoading,
} from "../../context/loadingContext";
import { isFocusable } from "@testing-library/user-event/dist/utils";
import Truck from "../../assets/images/Trucks_Daf.png";
import Van from "../../assets/images/Trucks_Sprinter.png";
import { Button as AntButton, DatePicker as AntDate } from 'antd'
import { PrinterFilled } from "@ant-design/icons";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  dateField: {
    marginRight: "10px",
    height: "40px",
    "& .react-date-picker__wrapper": {
      border: "thin solid #b6babf",
      borderRadius: '5px',
    },
    "& .react-date-picker__button svg ": {
      stroke: "#b6babf",
    }
  },
  btn: {
    marginRight: "10px !important",
  },
}));
const options = ["Financial Summary Excel", "Financial Summary PDF"];

export default function PrintReports() {
  const classes = useStyles();
  var layoutDispatch = useLoadingDispatch();
  const [deliveryDate, setDeliveryDate] = React.useState(new Date());
  const [routes, setRoutes] = React.useState([]);
  const [selectedRoute, setSelectedRoute] = React.useState([]);
  const [routePallets, setRoutePallets] = React.useState([]);
  const [pallets, setPallets] = React.useState([]);
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const Auth = new AuthService();

  const loadRoutes = () => {
    setType("success");
    setRoutes([]);
    setLoadMessage("Loading routes. Please wait ....");
    showLoading(layoutDispatch);
    Auth.post(`/route/load-locked-routes`, {
      delivery_date: moment(deliveryDate).format("YYYY-MM-DD"),
    })
      .then((res) => {
        hideLoading(layoutDispatch);
        setLoadMessage("Routes loaded .");
        setRoutes(res.routes);
        loadDailyRoute();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadDailyRoute = () => {
    setRoutePallets([]);
    setPallets([]);

    setType("success");

    setLoadMessage("Loading orders. Please wait ....");

    // Load saved route
    showLoading(layoutDispatch);
    Auth.fetch(
      `/route/load-daily-route?delivery_date=${moment(deliveryDate).format(
        "YYYY-MM-DD"
      )}`
    )
      .then((res) => {
        hideLoading(layoutDispatch);
        if (res.ack) {
          setLoadMessage("Pallet builder loaded .");
          let route_pallets = res.route_pallets;

          let route_pallets1 = [];
          let pallets1 = [];
          route_pallets.map((pallet, idx) => {
            // Checking Route
            let checkRoute = route_pallets1.filter(
              (rp) => rp.route_status_id == pallet.route_status_id
            );
            if (checkRoute.length > 0) {
              // Route Already exists, check pallet in that route
              let checkPallet = checkRoute[0].pallets.filter(
                (p) => p.id == pallet.pallet_builder_id
              );
              if (checkPallet.length > 0) {
                // Pallet already added, check for Order
                let checkPalletOrder = checkPallet[0].items.filter(
                  (po) => po.internalId == pallet.internal_id
                );
                if (checkPalletOrder.length > 0) {
                  // order already added, just add the item

                  let palletOrderItem = {};
                  palletOrderItem.itemId = pallet.item_id;
                  palletOrderItem.itemName = pallet.item_name;
                  palletOrderItem.itemTotalWeight = pallet.item_total_weight;
                  palletOrderItem.itemWeight = pallet.item_weight;
                  palletOrderItem.quantity = pallet.quantity;
                  palletOrderItem.unitPrice = pallet.unit_price;
                  palletOrderItem.grossAmountIncludingTax =
                    pallet.gross_amount_incl_tax;
                  palletOrderItem.frozenSheetItem = pallet.frozen_sheet_item;
                  checkPalletOrder[0].items.push(palletOrderItem);

                  checkPalletOrder[0].orderWeight += Number(
                    pallet.item_total_weight
                  );
                  // checkPalletOrder[0].orderAmount += (Number(pallet.unit_price) * Number(pallet.quantity));
                  checkPalletOrder[0].orderAmount += Number(
                    pallet.gross_amount_incl_tax
                  );
                  
                  checkPallet[0].weight += Number(pallet.item_total_weight);
                } else {
                  // New order, in previous pallet
                  let palletOrder = {};
                  palletOrder.id = idx + "" + 1;
                  palletOrder.rank = pallet.rank;
                  palletOrder.customerName = pallet.customer_name;
                  palletOrder.customerProjectId = pallet.customer_project_id;
                  palletOrder.shipZip = pallet.ship_zip;
                  palletOrder.customerOpeningTime =
                    pallet.customer_opening_time;
                  palletOrder.terms = pallet.terms;
                  palletOrder.shipAddress1 = pallet.ship_address1;

                  palletOrder.internalId = pallet.internal_id;
                  palletOrder.invoiceNo = pallet.invoice_no;
                  palletOrder.saleOrderNo = pallet.sale_order_no;
                  
                  palletOrder.orderWeight = 0;
                  palletOrder.orderAmount = 0;
                  palletOrder.amountReceivedCash = pallet.amount_received_cash;
                  palletOrder.amountReceivedCard = pallet.amount_received_card;
                  palletOrder.amountReceivedAccount = pallet.amount_received_account;
                  
                  palletOrder.items = [];

                  let palletOrderItem = {};
                  palletOrderItem.itemId = pallet.item_id;
                  palletOrderItem.itemName = pallet.item_name;
                  palletOrderItem.itemTotalWeight = pallet.item_total_weight;
                  palletOrderItem.itemWeight = pallet.item_weight;
                  palletOrderItem.quantity = pallet.quantity;
                  palletOrderItem.unitPrice = pallet.unit_price;
                  palletOrderItem.grossAmountIncludingTax =
                    pallet.gross_amount_incl_tax;
                  palletOrderItem.frozenSheetItem = pallet.frozen_sheet_item;
                  palletOrder.items.push(palletOrderItem);

                  palletOrder.orderWeight += Number(pallet.item_total_weight);
                  // palletOrder.orderAmount += (Number(pallet.unit_price) * Number(pallet.quantity));
                  palletOrder.orderAmount += Number(
                    pallet.gross_amount_incl_tax
                  );

                  checkPallet[0].weight += Number(pallet.item_total_weight);

                  checkPallet[0].items.push(palletOrder);
                }
              } else {
                // new pallet
                let palletObj = {};
                palletObj.id = pallet.pallet_builder_id;
                palletObj.title = pallet.title;
                palletObj.pallet_id = pallet.pallet_id;
                palletObj.weight = 0;
                palletObj.items = [];

                if (pallet.internal_id) {
                  let palletOrder = {};
                  palletOrder.id = idx + "" + 1;
                  palletOrder.rank = pallet.rank;
                  palletOrder.customerName = pallet.customer_name;
                  palletOrder.customerProjectId = pallet.customer_project_id;
                  palletOrder.shipZip = pallet.ship_zip;
                  palletOrder.customerOpeningTime =
                    pallet.customer_opening_time;
                  palletOrder.terms = pallet.terms;
                  palletOrder.shipAddress1 = pallet.ship_address1;

                  palletOrder.internalId = pallet.internal_id;
                  palletOrder.invoiceNo = pallet.invoice_no;
                  palletOrder.saleOrderNo = pallet.sale_order_no;
                  

                  palletOrder.orderWeight = 0;
                  palletOrder.orderAmount = 0;

                  palletOrder.amountReceivedCash = pallet.amount_received_cash;
                  palletOrder.amountReceivedCard = pallet.amount_received_card;
                  palletOrder.amountReceivedAccount = pallet.amount_received_account;
                  
                  
                  palletOrder.items = [];

                  let palletOrderItem = {};
                  palletOrderItem.itemId = pallet.item_id;
                  palletOrderItem.itemName = pallet.item_name;
                  palletOrderItem.itemTotalWeight = pallet.item_total_weight;
                  palletOrderItem.itemWeight = pallet.item_weight;
                  palletOrderItem.quantity = pallet.quantity;
                  palletOrderItem.unitPrice = pallet.unit_price;
                  palletOrderItem.grossAmountIncludingTax =
                    pallet.gross_amount_incl_tax;
                  palletOrderItem.frozenSheetItem = pallet.frozen_sheet_item;
                  palletOrder.items.push(palletOrderItem);
                  palletOrder.orderWeight += Number(pallet.item_total_weight);
                  // palletOrder.orderAmount += (Number(pallet.unit_price) * Number(pallet.quantity));
                  palletOrder.orderAmount += Number(
                    pallet.gross_amount_incl_tax
                  );

                  palletObj.items.push(palletOrder);
                  palletObj.weight += Number(palletOrder.orderWeight);
                }
                checkRoute[0].pallets.push(palletObj);
              }
            } else {
              let newRouteObj = {};
              newRouteObj.route_status_id = pallet.route_status_id;
              newRouteObj.pallets = [];

              // new pallet
              let palletObj = {};
              palletObj.id = pallet.pallet_builder_id;
              palletObj.title = pallet.title;
              palletObj.pallet_id = pallet.pallet_id;
              palletObj.weight = 0;
              palletObj.items = [];

              if (pallet.internal_id) {
                let palletOrder = {};
                palletOrder.id = idx + "" + 1;
                palletOrder.rank = pallet.rank;
                palletOrder.customerName = pallet.customer_name;
                palletOrder.customerProjectId = pallet.customer_project_id;
                palletOrder.shipZip = pallet.ship_zip;
                palletOrder.customerOpeningTime = pallet.customer_opening_time;
                palletOrder.terms = pallet.terms;
                palletOrder.shipAddress1 = pallet.ship_address1;

                palletOrder.internalId = pallet.internal_id;
                palletOrder.invoiceNo = pallet.invoice_no;
                palletOrder.saleOrderNo = pallet.sale_order_no;
                  
                palletOrder.orderWeight = 0;
                palletOrder.orderAmount = 0;

                palletOrder.items = [];

                let palletOrderItem = {};
                palletOrderItem.itemId = pallet.item_id;
                palletOrderItem.itemName = pallet.item_name;
                palletOrderItem.itemTotalWeight = pallet.item_total_weight;
                palletOrderItem.itemWeight = pallet.item_weight;
                palletOrderItem.quantity = pallet.quantity;
                palletOrderItem.unitPrice = pallet.unit_price;

                palletOrderItem.grossAmountIncludingTax =
                  pallet.gross_amount_incl_tax;
                palletOrderItem.frozenSheetItem = pallet.frozen_sheet_item;
                palletOrder.items.push(palletOrderItem);
                palletOrder.orderWeight += Number(pallet.item_total_weight);
                // palletOrder.orderAmount += (Number(pallet.unit_price) * Number(pallet.quantity));;
                palletOrder.orderAmount += Number(pallet.gross_amount_incl_tax);

                palletObj.items.push(palletOrder);
                palletObj.weight += Number(palletOrder.orderWeight);
              }

              newRouteObj.pallets.push(palletObj);

              route_pallets1.push(newRouteObj);
            }
          });
          // console.log(route_pallets1);
          setRoutePallets(route_pallets1);
        } else {
          setType("error");
          setLoadMessage("Pallet builder not saved for selected route ....");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadRoutePallets = (route_status_id) => {
    setSelectedRoute({});
    setLoadMessage("");
    let route = routes.filter((r) => r.id == route_status_id);

    if (route.length == 0) {
      return;
    }

    setSelectedRoute(route[0]);
    setPallets([]);

    let pallets1 = routePallets.filter(
      (rp) => rp.route_status_id == route_status_id
    );
    if (pallets1.length > 0) {
      setPallets(pallets1[0].pallets);
    } else {
      setType("error");
      setLoadMessage(
        "Pallet builder not saved for this route for " + route[0].title
      );
      // alert("Pallet builder not saved for this route !");
    }
  };

  const printCollectionOrders = () => {
    let pdf = new jsPDF("p", "pt", "A4");

    let col = ["Item Code", "Description", "Weight", "QTY"];
    var startrow = 25;
    let pageNo = 1;

    setLoadMessage("");
    Auth.fetch(
      `/route/get-collection-orders?delivery_date=${moment(deliveryDate).format(
        "YYYY-MM-DD"
      )}`
    )
      .then((res) => {
        if (res.ack) {
          const orders = res.orders;
          let orderData = [];
          if (orders.length > 0) {
            orders.map((order) => {
              let check = orderData.filter(
                (r) => r.internalId == order.internalId
              );
              if (check.length > 0) {
                let nsItem = {};
                nsItem.itemId = order.itemId;
                nsItem.itemName = order.itemName;
                nsItem.quantity = order.quantity;
                nsItem.quantityFulfilled = order.quantityFulfilled;
                nsItem.itemWeight = order.itemWeight;
                check[0].orderWeight =
                  Number(check[0].orderWeight) +
                  Number(order.itemWeight) * Number(order.quantityFulfilled); // .substring(0, order.itemWeight.length - 2) -- removed because KG is removed after we insert into table

                nsItem.casesInPallet = order.casesInPallet;
                nsItem.unitPrice = order.unitPrice;
                nsItem.netAmount = order.netAmount;
                nsItem.grossAmount = order.grossAmount;
                nsItem.grossAmountIncludingTax = order.grossAmountIncludingTax;

                check[0].items.push(nsItem);
              } else {
                let nsOrder = {};

                nsOrder.internalId = order.internalId;
                nsOrder.invInternalId = order.invInternalId;
                nsOrder.saleOrderNo = order.saleOrderNo;
                nsOrder.invoiceNo = order.invoiceNo;
                nsOrder.collectionTime = order.collectionTime;
                nsOrder.tranDate = order.tranDate;
                nsOrder.deliverDate = order.deliverDate;
                nsOrder.customerName = order.customerName;
                nsOrder.customerInternalId = order.customerInternalId;
                nsOrder.customerProjectId = order.customerProjectId;
                nsOrder.shipAddress1 = order.shipAddress1;
                nsOrder.shipAddress2 = order.shipAddress2;
                nsOrder.shipCity = order.shipCity;
                nsOrder.shipState = order.shipState;
                nsOrder.shipZip = order.shipZip;
                nsOrder.shipCountryCode = order.shipCountryCode;
                nsOrder.customerLatitude = order.customerLatitude;
                nsOrder.customerLongitude = order.customerLongitude;
                nsOrder.customerOpeningTime = order.customerOpeningTime;
                nsOrder.customerClosingTime = order.customerClosingTime;
                nsOrder.customerMaxvehicleLoadType =
                  order.customerMaxvehicleLoadType;
                nsOrder.platesInOrder = order.platesInOrder;
                nsOrder.customerOffLoadingMethod =
                  order.customerOffLoadingMethod;
                nsOrder.saleOrderType = order.saleOrderType;
                nsOrder.status = order.status;
                nsOrder.addressShippingAttentiona =
                  order.addressShippingAttentiona;
                nsOrder.orderCollectionToBePicked =
                  order.orderCollectionToBePicked;

                nsOrder.orderWeight = 0;

                nsOrder.orderWeight =
                  Number(order.itemWeight) * Number(order.quantityFulfilled); // .substring(0, order.itemWeight.length - 2) -- removed because KG is removed after we insert into table

                nsOrder.items = [];

                let nsItem = {};
                nsItem.itemId = order.itemId;
                nsItem.itemName = order.itemName;
                nsItem.quantity = order.quantity;
                nsItem.quantityFulfilled = order.quantityFulfilled;

                nsItem.itemWeight = order.itemWeight;
                nsItem.casesInPallet = order.casesInPallet;
                nsItem.unitPrice = order.unitPrice;
                nsItem.netAmount = order.netAmount;
                nsItem.grossAmount = order.grossAmount;
                nsItem.grossAmountIncludingTax = order.grossAmountIncludingTax;

                nsOrder.items.push(nsItem);

                orderData.push(nsOrder);
              }
            });
            // console.log(orderData)
            var rows = [];
            const totalPagesExp = "{total_pages_count_string}";
            let totalRoutes = orderData;
            let routeNo = 1;
            orderData.map((order) => {
              let orderWeight = 0;
              order.items.sort(function (a, b) {
                return a.itemId.localeCompare(b.itemId, "en", {
                  numeric: true,
                });
              });

              rows = [];
              order.items.map((item) => {
                if (item.itemId != "Delivery Charge") {
                  let row = [];
                  orderWeight +=
                    Number(item.itemWeight) * Number(item.quantityFulfilled);

                  row.push(item.itemId);
                  row.push(item.itemName);
                  row.push(
                    (item.itemWeight * Number(item.quantityFulfilled)).toFixed(
                      2
                    ) + " KG"
                  );
                  row.push(item.quantityFulfilled);

                  rows.push(row);
                  row = [];
                }
              });

              let formatted_date = moment(deliveryDate).format("DD-MMM-YYYY");
              let customer =
                "(" + order.customerName + ") - " + order.customerProjectId;
              var pageContent = function (data) {
                // HEADER
                pdf.setFontSize(20);
                pdf.text("Customer Collection", 200, startrow + 10);
                // pdf.text(title, 50, startrow + 10);

                pdf.setFontSize(10);

                pdf.text(
                  "Invoice Number: " + order.invoiceNo,
                  40,
                  startrow + 40
                );
                pdf.text(
                  "Order Weight: " + orderWeight.toFixed(2) + " KG",
                  40,
                  startrow + 60
                );
                pdf.text("Date: " + formatted_date, 480, startrow + 10);

                pdf.setFontSize(10);
                pdf.text("Customer: " + customer, 40, startrow + 80);
                // pdf.text("Collection Time: 08:00", 430, startrow + 30);

                var headerheight = 50;
                //2nd small box

                pdf.setFontSize(10);
                pdf.text("Customer Signature:", 400, startrow + 80);
                pdf.line(495, startrow + 82, 560, startrow + 82);

                pdf.setFontSize(20);
                pdf.text(
                  "Collection at: " + order.collectionTime,
                  380,
                  startrow + 50
                );
                pdf.setLineWidth(1.0);
                pdf.line(370, startrow + 30, 560, startrow + 30);
                pdf.line(370, startrow + 60, 560, startrow + 60);
                pdf.line(370, startrow + 30, 370, startrow + 60);
                pdf.line(560, startrow + 30, 560, startrow + 60);

                // FOOTER
                var str = "Page " + pageNo;
                // Total page number plugin only available in jspdf v1.0+
                if (typeof pdf.putTotalPages === "function") {
                  str = str; // + " of " + totalPagesExp;
                }
                pdf.setFontSize(10);
                var pageHeight =
                  pdf.internal.pageSize.height ||
                  pdf.internal.pageSize.getHeight();
                pdf.text(str, data.settings.margin.left, pageHeight - 20); // showing current page number
                pageNo += 1;
              };

              pdf.autoTable(col, rows, {
                didDrawPage: pageContent,
                theme: "grid",
                margin: { top: startrow + 90 },
                styles: {
                  fontSize: 8,
                  valign: "middle",
                  halign: "center",
                  align: "center",
                },

                columnStyles: {
                  0: {
                    columnWidth: 70,
                    valign: "left",
                    halign: "left",
                    align: "left",
                  },
                  1: {
                    columnWidth: 290,
                    valign: "left",
                    halign: "left",
                    align: "left",
                  },
                  2: {
                    columnWidth: 80,
                    valign: "right",
                    halign: "right",
                    align: "right",
                  },
                  3: {
                    columnWidth: 80,
                    valign: "right",
                    halign: "right",
                    align: "right",
                  },
                },
              });

              //for adding total number of pages // i.e 10 etc
              if (typeof pdf.putTotalPages === "function") {
                pdf.putTotalPages(totalPagesExp);
              }
              // if (routeNo < totalRoutes) {
              //     pdf.addPage();
              // }
              // routeNo = routeNo + 1;

              pdf.addPage();
            });
            pdf.save(
              moment(deliveryDate).format("DD-MM-YYYY") +
                "-" +
                "Collection-Orders-Detailed.pdf"
            );
          }
        } else {
          setType("error");
          setLoadMessage("Collection orders not found");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const printCollectionOrdersSummary = () => {
    let pdf = new jsPDF("p", "pt", "A4");

    let col = [
      "Sr No.",
      "Invoice No.",
      "Shop Code",
      "Customer Name",
      "Weight",
      "Collection time",
    ];
    var startrow = 25;
    let pageNo = 1;

    Auth.fetch(
      `/route/get-collection-orders?delivery_date=${moment(deliveryDate).format(
        "YYYY-MM-DD"
      )}`
    )
      .then((res) => {
        if (res.ack) {
          console.log(res.orders);
          const orders = res.orders;
          let orderData = [];
          if (orders.length > 0) {
            orders.map((order) => {
              let check = orderData.filter(
                (r) => r.internalId == order.internalId
              );
              if (check.length > 0) {
                let nsItem = {};
                nsItem.itemId = order.itemId;
                nsItem.itemName = order.itemName;
                nsItem.quantity = order.quantity;
                nsItem.quantityFulfilled = order.quantityFulfilled;

                nsItem.itemWeight = order.itemWeight;
                check[0].orderWeight =
                  Number(check[0].orderWeight) +
                  Number(order.itemWeight) * Number(order.quantityFulfilled); // .substring(0, order.itemWeight.length - 2) -- removed because KG is removed after we insert into table

                nsItem.casesInPallet = order.casesInPallet;
                nsItem.unitPrice = order.unitPrice;
                nsItem.netAmount = order.netAmount;
                nsItem.grossAmount = order.grossAmount;
                nsItem.grossAmountIncludingTax = order.grossAmountIncludingTax;

                check[0].items.push(nsItem);
              } else {
                let nsOrder = {};

                nsOrder.internalId = order.internalId;
                nsOrder.invInternalId = order.invInternalId;
                nsOrder.saleOrderNo = order.saleOrderNo;
                nsOrder.invoiceNo = order.invoiceNo;
                nsOrder.collectionTime = order.collectionTime;
                nsOrder.tranDate = order.tranDate;
                nsOrder.deliverDate = order.deliverDate;
                nsOrder.customerName = order.customerName;
                nsOrder.customerInternalId = order.customerInternalId;
                nsOrder.customerProjectId = order.customerProjectId;
                nsOrder.shipAddress1 = order.shipAddress1;
                nsOrder.shipAddress2 = order.shipAddress2;
                nsOrder.shipCity = order.shipCity;
                nsOrder.shipState = order.shipState;
                nsOrder.shipZip = order.shipZip;
                nsOrder.shipCountryCode = order.shipCountryCode;
                nsOrder.customerLatitude = order.customerLatitude;
                nsOrder.customerLongitude = order.customerLongitude;
                nsOrder.customerOpeningTime = order.customerOpeningTime;
                nsOrder.customerClosingTime = order.customerClosingTime;
                nsOrder.customerMaxvehicleLoadType =
                  order.customerMaxvehicleLoadType;
                nsOrder.platesInOrder = order.platesInOrder;
                nsOrder.customerOffLoadingMethod =
                  order.customerOffLoadingMethod;
                nsOrder.saleOrderType = order.saleOrderType;
                nsOrder.status = order.status;
                nsOrder.addressShippingAttentiona =
                  order.addressShippingAttentiona;
                nsOrder.orderCollectionToBePicked =
                  order.orderCollectionToBePicked;

                nsOrder.orderWeight = 0;

                nsOrder.orderWeight =
                  Number(order.itemWeight) * Number(order.quantityFulfilled); // .substring(0, order.itemWeight.length - 2) -- removed because KG is removed after we insert into table

                nsOrder.items = [];

                let nsItem = {};
                nsItem.itemId = order.itemId;
                nsItem.itemName = order.itemName;
                nsItem.quantity = order.quantity;
                nsItem.quantityFulfilled = order.quantityFulfilled;
                nsItem.itemWeight = order.itemWeight;
                nsItem.casesInPallet = order.casesInPallet;
                nsItem.unitPrice = order.unitPrice;
                nsItem.netAmount = order.netAmount;
                nsItem.grossAmount = order.grossAmount;
                nsItem.grossAmountIncludingTax = order.grossAmountIncludingTax;

                nsOrder.items.push(nsItem);

                orderData.push(nsOrder);
              }
            });
            var rows = [];
            const totalPagesExp = "{total_pages_count_string}";

            let formatted_date = moment(deliveryDate).format("DD-MMM-YYYY");

            orderData.map((order, idx) => {
              let orderWeight = 0;
              order.items.map((item) => {
                if (item.itemId != "Delivery Charge") {
                  orderWeight +=
                    Number(item.itemWeight) * Number(item.quantityFulfilled);
                }
              });

              let row = [];
              row.push(idx + 1);
              row.push(order.invoiceNo);
              row.push(order.customerName);
              row.push(order.customerProjectId);
              row.push(orderWeight.toFixed(2) + " KG");
              row.push(order.collectionTime);

              rows.push(row);
              row = [];
            });
            var pageContent = function (data) {
              // HEADER
              pdf.setFontSize(20);
              pdf.text("Customer Collections - Summary", 150, startrow + 10);
              // pdf.text(title, 50, startrow + 10);

              pdf.setFontSize(10);

              pdf.text("Date: " + formatted_date, 480, startrow + 10);

              // FOOTER
              var str = "Page " + pageNo;
              // Total page number plugin only available in jspdf v1.0+
              if (typeof pdf.putTotalPages === "function") {
                str = str; // + " of " + totalPagesExp;
              }
              pdf.setFontSize(10);
              var pageHeight =
                pdf.internal.pageSize.height ||
                pdf.internal.pageSize.getHeight();
              pdf.text(str, data.settings.margin.left, pageHeight - 20); // showing current page number
              pageNo += 1;
            };

            pdf.autoTable(col, rows, {
              didDrawPage: pageContent,
              theme: "grid",
              margin: { top: startrow + 30 },
              styles: {
                fontSize: 8,
                valign: "middle",
                halign: "center",
                align: "center",
              },

              columnStyles: {
                0: {
                  columnWidth: 35,
                  valign: "left",
                  halign: "left",
                  align: "left",
                },
                1: {
                  columnWidth: 60,
                  valign: "left",
                  halign: "left",
                  align: "left",
                },
                2: {
                  columnWidth: 60,
                  valign: "left",
                  halign: "left",
                  align: "left",
                },
                3: {
                  columnWidth: 240,
                  valign: "left",
                  halign: "left",
                  align: "left",
                },
                4: {
                  columnWidth: 60,
                  valign: "right",
                  halign: "right",
                  align: "right",
                },
                5: {
                  columnWidth: 75,
                  valign: "right",
                  halign: "right",
                  align: "right",
                },
              },
            });

            //for adding total number of pages // i.e 10 etc
            if (typeof pdf.putTotalPages === "function") {
              pdf.putTotalPages(totalPagesExp);
            }

            pdf.save(
              moment(deliveryDate).format("DD-MM-YYYY") +
                "-" +
                "Collection-Orders-Summary.pdf"
            );
          }
        } else {
          setType("error");
          setLoadMessage("Collection orders not found");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const printPalletSheet = () => {
    let pdf = new jsPDF("p", "pt", "A4");

    let col = ["Description", "Shop Code", "Shop Name", "QTY"];
    var startrow = 25;
    let pageNo = 1;

    const totalPagesExp = "{total_pages_count_string}";
    pdf.setFontSize(14);

    pdf.text(moment(deliveryDate).format("DD-MMM-YYYY"), 250, startrow + 10);
    pdf.text("Truck Picking & Packing", 220, startrow + 30);
    pdf.text("Route: " + selectedRoute.title, 240, startrow + 50);
    pdf.text("Truck: " + selectedRoute.registration_number, 240, startrow + 70);
    pdf.line(15, startrow + 80, 570, startrow + 80);
    let col1 = ["Pallet No.", "Packed by", "Checked By", "Weight", "Loaded By"];
    let rows1 = [];
    pallets.map((p, idx) => {
      let row1 = [];
      row1.push("Pallet " + (Number(idx) + 1));
      row1.push("");
      row1.push("");
      row1.push("");
      row1.push("");
      rows1.push(row1);
    });

    pdf.autoTable(col1, rows1, {
      theme: "grid",
      margin: {
        top: startrow + 110,
        left: 15,
      },
      styles: {
        fontSize: 8,
        valign: "middle",
        halign: "center",
        align: "center",
      },
      columnStyles: {
        0: { cellWidth: 60 },
        1: { cellWidth: 140 },
        2: { cellWidth: 140 },
        3: { cellWidth: 70 },
        4: { cellWidth: 140 },
      },
    });

    pdf.text("Approved by: ", 380, startrow + 600);
    pdf.text("Signature: ", 380, startrow + 650);
    pdf.line(450, startrow + 650, 560, startrow + 650);

    // FOOTER
    var str = "Page " + pageNo;
    // Total page number plugin only available in jspdf v1.0+
    if (typeof pdf.putTotalPages === "function") {
      str = str; // + " of " + totalPagesExp;
    }
    pdf.setFontSize(10);
    var pageHeight =
      pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
    pdf.text(str, 20, pageHeight - 20); // showing current page number
    pageNo += 1;
    pdf.addPage();

    pallets.map((pallet, idx) => {
      console.log(pallet);
      if (pallet.items.length > 0) {
        let palletNo = idx + 1;
        let rows = [];
        let pallet_qty = 0;

        for (var idx = pallet.items.length - 1; idx >= 0; idx--) {
          pallet.items[idx].items.map((item) => {
            if (item.itemId != "Delivery Charge") {
              pallet_qty += Number(item.quantity);
              let tRow = [];
              tRow.push(item.itemName);
              tRow.push(pallet.items[idx].customerName);
              tRow.push(pallet.items[idx].customerProjectId);
              tRow.push(item.quantity);
              rows.push(tRow);
            }
          });
        }
        // pallet.items.map((order) => {
        //     order.items.map((item) => {
        //         if (item.itemId != 'Delivery Charge') {

        //             pallet_qty += Number(item.quantity);
        //             let tRow = [];
        //             tRow.push(item.itemName);
        //             tRow.push(order.customerName);
        //             tRow.push(order.customerProjectId);
        //             tRow.push(item.quantity);
        //             rows.push(tRow);
        //         }
        //     });
        // });

        const pageContent = (data) => {
          pdf.setFontSize(14);
          pdf.text(moment(deliveryDate).format("DD-MMMM-YYYY"), 415, startrow);
          pdf.setLineWidth(2.0);
          pdf.line(15, startrow + 12, 350, startrow + 12);
          pdf.setFontSize(12);
          pdf.text("PALLET QUANTITY", 25, startrow + 5);
          pdf.text("PALLET WEIGHT", 250, startrow + 5);
          pdf.setLineWidth(1.0);

          //PaLLET weight
          pdf.line(15, startrow + 30, 350, startrow + 30);
          pdf.setFontSize(50);
          pdf.text(selectedRoute.registration_number, 40, startrow + 110);
          pdf.line(15, startrow + 150, 350, startrow + 150);
          pdf.line(15, startrow + 290, 350, startrow + 290);
          pdf.line(15, startrow + 12, 15, startrow + 290);
          pdf.line(350, startrow + 12, 350, startrow + 290);
          var i;
          pdf.setFontSize(12);

          pdf.text("" + pallet_qty, 80, startrow + 25);
          pdf.text("" + pallet.weight.toFixed(2), 300, startrow + 25);
          pdf.setFontSize(18);
          if (palletNo == 1) {
            pdf.text("" + palletNo, 420, startrow + 65);
          } else if (palletNo == 2) {
            pdf.text("" + palletNo, 510, startrow + 65);
          } else if (palletNo == 3) {
            pdf.text("" + palletNo, 420, startrow + 95);
          } else if (palletNo == 4) {
            pdf.text("" + palletNo, 510, startrow + 95);
          } else if (palletNo == 5) {
            pdf.text("" + palletNo, 420, startrow + 125);
          } else if (palletNo == 6) {
            pdf.text("" + palletNo, 510, startrow + 125);
          } else if (palletNo == 7) {
            pdf.text("" + palletNo, 420, startrow + 155);
          } else if (palletNo == 8) {
            pdf.text("" + palletNo, 510, startrow + 155);
          } else if (palletNo == 9) {
            pdf.text("" + palletNo, 420, startrow + 185);
          } else if (palletNo == 10) {
            pdf.text("" + palletNo, 510, startrow + 185);
          } else if (palletNo == 11) {
            pdf.text("" + palletNo, 420, startrow + 215);
          } else if (palletNo == 12) {
            pdf.text("" + palletNo, 510, startrow + 215);
          } else if (palletNo == 13) {
            pdf.text("" + palletNo, 420, startrow + 245);
          } else if (palletNo == 14) {
            pdf.text("" + palletNo, 510, startrow + 245);
          }

          //Inside Pallet Weight Box
          pdf.line(28, startrow + 190, 85, startrow + 190);
          pdf.line(28, startrow + 280, 85, startrow + 280);
          pdf.line(28, startrow + 190, 28, startrow + 280);
          pdf.line(85, startrow + 190, 85, startrow + 280);
          pdf.setFontSize(9);
          var row = 1;
          pallet.items.forEach((obj) => {
            pdf.text("" + obj.rank, 90, startrow + 200 + 15 * row);
            pdf.text(
              " - " + obj.customerProjectId + " (" + obj.customerName + ")",
              105,
              startrow + 200 + 15 * row
            );
            row = row + 1;
          });

          //Driver
          pdf.setFontSize(9);
          pdf.text(
            "DRIVER - (" + selectedRoute.driver_name + ")",
            368,
            startrow + 20
          );
          pdf.setLineWidth(2.0);
          pdf.setFontSize(12);
          pdf.line(370, startrow + 30, 575, startrow + 30);
          pdf.line(370, startrow + 272, 575, startrow + 272);
          pdf.line(370, startrow + 30, 370, startrow + 272);
          pdf.line(575, startrow + 30, 575, startrow + 272);
          pdf.text("TAILIFT", 430, startrow + 290);
          //Inside Driver
          pdf.setLineWidth(1.0);
          pdf.setFontSize(20);
          // pdf.text("2", 526, startrow+91);
          pdf.line(385, startrow + 50, 385, startrow + 70);
          pdf.line(385, startrow + 80, 385, startrow + 100);
          pdf.line(385, startrow + 110, 385, startrow + 130);
          pdf.line(385, startrow + 140, 385, startrow + 160);
          pdf.line(385, startrow + 170, 385, startrow + 190);
          pdf.line(385, startrow + 200, 385, startrow + 220);
          pdf.line(385, startrow + 230, 385, startrow + 250);

          pdf.line(465, startrow + 50, 465, startrow + 70);
          pdf.line(465, startrow + 80, 465, startrow + 100);
          pdf.line(465, startrow + 110, 465, startrow + 130);
          pdf.line(465, startrow + 140, 465, startrow + 160);
          pdf.line(465, startrow + 170, 465, startrow + 190);
          pdf.line(465, startrow + 200, 465, startrow + 220);
          pdf.line(465, startrow + 230, 465, startrow + 250);

          pdf.line(475, startrow + 50, 475, startrow + 70);
          pdf.line(475, startrow + 80, 475, startrow + 100);
          pdf.line(475, startrow + 110, 475, startrow + 130);
          pdf.line(475, startrow + 140, 475, startrow + 160);
          pdf.line(475, startrow + 170, 475, startrow + 190);
          pdf.line(475, startrow + 200, 475, startrow + 220);
          pdf.line(475, startrow + 230, 475, startrow + 250);

          pdf.line(560, startrow + 50, 560, startrow + 70);
          pdf.line(560, startrow + 80, 560, startrow + 100);
          pdf.line(560, startrow + 110, 560, startrow + 130);
          pdf.line(560, startrow + 140, 560, startrow + 160);
          pdf.line(560, startrow + 170, 560, startrow + 190);
          pdf.line(560, startrow + 200, 560, startrow + 220);
          pdf.line(560, startrow + 230, 560, startrow + 250);

          pdf.line(385, startrow + 50, 465, startrow + 50);
          pdf.line(475, startrow + 50, 560, startrow + 50);
          pdf.line(385, startrow + 70, 465, startrow + 70);
          pdf.line(475, startrow + 70, 560, startrow + 70);

          pdf.line(385, startrow + 80, 465, startrow + 80);
          pdf.line(475, startrow + 80, 560, startrow + 80);
          pdf.line(385, startrow + 100, 465, startrow + 100);
          pdf.line(475, startrow + 100, 560, startrow + 100);

          pdf.line(385, startrow + 110, 465, startrow + 110);
          pdf.line(475, startrow + 110, 560, startrow + 110);
          pdf.line(385, startrow + 130, 465, startrow + 130);
          pdf.line(475, startrow + 130, 560, startrow + 130);

          pdf.line(385, startrow + 140, 465, startrow + 140);
          pdf.line(475, startrow + 140, 560, startrow + 140);
          pdf.line(385, startrow + 160, 465, startrow + 160);
          pdf.line(475, startrow + 160, 560, startrow + 160);

          pdf.line(385, startrow + 170, 465, startrow + 170);
          pdf.line(475, startrow + 170, 560, startrow + 170);
          pdf.line(385, startrow + 190, 465, startrow + 190);
          pdf.line(475, startrow + 190, 560, startrow + 190);

          pdf.line(385, startrow + 200, 465, startrow + 200);
          pdf.line(475, startrow + 200, 560, startrow + 200);
          pdf.line(385, startrow + 220, 465, startrow + 220);
          pdf.line(475, startrow + 220, 560, startrow + 220);

          pdf.line(385, startrow + 230, 465, startrow + 230);
          pdf.line(475, startrow + 230, 560, startrow + 230);
          pdf.line(385, startrow + 250, 465, startrow + 250);
          pdf.line(475, startrow + 250, 560, startrow + 250);

          // FOOTER
          var str = "Page " + pageNo; // data.pageCount;
          // Total page number plugin only available in jspdf v1.0+
          if (typeof pdf.putTotalPages === "function") {
            str = str; // + " of " + totalPagesExp;
          }
          pdf.setFontSize(10);
          var pageHeight =
            pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
          pdf.text(str, data.settings.margin.left, pageHeight - 20); // showing current page number

          pageNo += 1;
        };

        pdf.autoTable(col, rows, {
          didDrawPage: pageContent,
          theme: "grid",
          margin: {
            top: startrow + 310,
            left: 15,
          },
          styles: {
            fontSize: 8,
            valign: "middle",
            halign: "center",
            align: "center",
          },
          columnStyles: {
            0: { cellWidth: 230 },
            1: { cellWidth: 60 },
            2: { cellWidth: 230 },
            3: { cellWidth: 45 },
            3: { cellWidth: 45 },
          },
        });

        //for adding total number of pages // i.e 10 etc
        if (typeof pdf.putTotalPages === "function") {
          pdf.putTotalPages(totalPagesExp);
        }
        pdf.addPage();
      }
    });
    pdf.save(
      selectedRoute.code +
        "-" +
        moment(deliveryDate).format("DD-MM-YYYY") +
        "-" +
        selectedRoute.registration_number.replace(" ", "-") +
        ".pdf"
    );
  };

  const printDriverManifest = () => {
    let col = [
      "S. No.",
      "Shop Code",
      "Shop Name",
      "Address",
      "Post Code",
      "Weight (KG)",
      "Terms",
    ]; // , 'Opening Time'
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var saleorderdate, transportrouteID;
    let title = "Driver Menifest"; // title of report

    let pdf = new jsPDF("l", "pt", "A4");
    var startrow = 20;
    var totalRoutes = routePallets.length;

    var routeNo = 1;
    var pageNo = 1;

    routePallets.map((routePallet) => {
      let route = routes.filter((r) => r.id == routePallet.route_status_id);

      if (route.length == 0) {
        return;
      } else {
        routePallet.selectedRoute = route[0];
        routePallet.driver_name = route[0].driver_name;
        routePallet.registration_number = route[0].registration_number;
      }
    });

    routePallets.sort(function (a, b) {
      return a.driver_name.localeCompare(b.driver_name);
    });

    routePallets.map((routePallet) => {
      // let route = routes.filter(r => r.id == routePallet.route_status_id);

      // if (route.length == 0) {
      //     return;
      // }
      // let selectedRoute1 = route[0];

      let rows = [];
      var total_weight = 0.0;
      var previousTotalPages = 0;
      const totalPagesExp = "{total_pages_count_string}";

      let pallets1 = JSON.parse(JSON.stringify(routePallet.pallets));
      pallets1.sort(function (a, b) {
        return b.pallet_id - a.pallet_id;
      });

      // console.log(pallets1)
      let all_orders = [];
      // pallets1.reduce((a, p) => {
      //     if (p.items.length > 0) {
      //         all_orders = all_orders.concat(p.items);
      //     }
      // });

      pallets1.map((pallet) => {
        if (pallet.items.length > 0)
          all_orders = all_orders.concat(pallet.items);
      });

      // console.log(all_orders)
      // pallets1.map((pallet) => {

      // sort by rank
      all_orders.sort(function (a, b) {
        return a.rank - b.rank;
      });

      all_orders.map((order, orderIdx) => {
        // let internalId = rows[rows.length - 1] && rows[rows.length - 1][0] == order.internalId ? true : false;
        // To check if the ranks are in reverse order within the orders items, need to check the whole list everytime
        let internalId = 0;
        let idx = -1;
        rows.map((r, i) => {
          if (r[0] == order.internalId) {
            internalId = order.internalId;
            idx = i;
          }
        });

        if (internalId > 0) {
          rows[idx][6] = (
            Number(rows[idx][6]) + Number(order.orderWeight)
          ).toFixed(2); // order weight
          total_weight += Number(order.orderWeight);
        } else {
          let row = [];
          row.push(order.internalId); // temporary adding
          row.push(order.rank);
          row.push(order.customerName);
          row.push(order.customerProjectId);
          row.push(order.shipAddress1);
          row.push(order.shipZip);
          row.push(order.orderWeight.toFixed(2)); // + " KG" removed as it was giving NaN after adding the same rank order weight to id
          total_weight += Number(order.orderWeight);
          row.push(order.terms == "Cash on Delivery" ? order.terms : "");
          // row.push(order.customerOpeningTime+":00")
          rows.push(row);
        }

        //title = "Driver Menifest ("+obj.transportroute_ID.transportroute_CODE+")";
      });
      // });
      rows.map((row) => {
        row.shift(); // removing the internal ID
      });

      var totalweight = total_weight.toFixed(2);
      let formatted_date = moment(deliveryDate).format("DD-MMMM-YYYY");
      var driver = routePallet.driver_name;
      var truck = routePallet.registration_number;
      var pageContent = function (data) {
        // HEADER
        pdf.setFontSize(14);

        pdf.text("STOP LIST", 50, startrow + 10);
        pdf.text(formatted_date, 600, startrow + 10);

        pdf.text("Driver : " + driver, 50, startrow + 30);

        pdf.text("Truck: " + truck, 600, startrow + 30);
        pdf.text(
          "Route Description: " +
            routePallet.selectedRoute.title +
            " (" +
            routePallet.selectedRoute.code +
            ")",
          300,
          startrow + 10
        );
        pdf.text("Total Orders Weight: " + totalweight, 600, startrow + 50);

        // FOOTER
        var str = "Page " + pageNo;
        // Total page number plugin only available in jspdf v1.0+
        if (typeof pdf.putTotalPages === "function") {
          str = str; // + " of " + totalPagesExp;
        }
        pdf.setFontSize(10);
        var pageHeight =
          pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
        pdf.text(str, data.settings.margin.left, pageHeight - 20); // showing current page number
        pageNo += 1;
      };

      pdf.autoTable(col, rows, {
        didDrawPage: pageContent,
        theme: "grid",
        margin: { top: startrow + 60 },
        styles: {
          fontSize: 8,
          valign: "middle",
          halign: "center",
          align: "center",
        },
        // createdCell: function (cell, data) {
        //     console.log(cell)
        //     if (cell.row.raw[6] === 'Cash on Delivery') {
        //        cell.cell.styles.fillColor = [255,0,0];
        //     }
        //     else
        //     {
        //         cell.cell.styles.fillColor = 255;
        //     }
        // },
        willDrawCell: function (data) {
          if (data.section == "body") {
            if (data.row.raw[6] == "Cash on Delivery") {
              console.log(data.row.cells[6].styles);

              data.row.cells[6].styles.fontSize = "12";
              data.row.cells[6].styles.color = "#000";
              data.row.cells[6].styles.fontStyle = "bold";
              data.row.cells[6].styles.fillColor = [219, 219, 219];
            }
          }
        },
        columnStyles: {
          0: { columnWidth: 50 },
          1: { columnWidth: 90 },
          2: { columnWidth: 230 },
          3: { columnWidth: 100 },
          4: { columnWidth: 100 },
          5: { columnWidth: 100 },
          6: { columnWidth: 100, color: "#FF0000" },
        },
      });

      // with opening time
      // 4: { columnWidth: 80 },
      // 5: { columnWidth: 80 },
      // 6: { columnWidth: 80 },
      //for adding total number of pages // i.e 10 etc
      if (typeof pdf.putTotalPages === "function") {
        pdf.putTotalPages(totalPagesExp);
      }
      if (routeNo < totalRoutes) {
        pdf.addPage();
      }
      routeNo = routeNo + 1;
    });
    pdf.save(
      moment(deliveryDate).format("DD-MM-YYYY") + "Driver-Manifest.pdf"
    );
  };

  const printDriverSheet = () => {
    let col = [
      "S. No.",
      "Shop Code",
      "Shop Name",
      "Invoice",
      "Total (£)",
      "Cash",
      "Online",
      "Account",
    ]; // initialization for headers
    let title = "Driver Sheet"; // title of report

    let pdf = new jsPDF("l", "pt", "A4");
    var startrow = 20;
    var totalRoutes = routePallets.length;

    var routeNo = 1;
    var pageNo = 1;

    routePallets.map((routePallet) => {
      let route = routes.filter((r) => r.id == routePallet.route_status_id);

      if (route.length == 0) {
        return;
      } else {
        routePallet.selectedRoute = route[0];
        routePallet.driver_name = route[0].driver_name;
        routePallet.registration_number = route[0].registration_number;
      }
    });

    routePallets.sort(function (a, b) {
      return a.driver_name.localeCompare(b.driver_name);
    });

    routePallets.map((routePallet) => {
      // let route = routes.filter(r => r.id == routePallet.route_status_id);

      // if (route.length == 0) {
      //     return;
      // }
      // let selectedRoute1 = route[0];

      var rows = [];
      const totalPagesExp = "{total_pages_count_string}";

      let pallets1 = JSON.parse(JSON.stringify(routePallet.pallets));
      pallets1.sort(function (a, b) {
        return b.pallet_id - a.pallet_id;
      });

      // console.log(pallets1)
      let all_orders = [];
      // pallets1.reduce((a, p)=>
      // {
      //     if(p.items.length > 0)
      //     {
      //         all_orders = all_orders.concat(p.items);
      //     }
      // });
      pallets1.map((pallet) => {
        if (pallet.items.length > 0)
          all_orders = all_orders.concat(pallet.items);
      });

      // console.log(all_orders)
      // pallets1.map((pallet) => {

      // sort by rank
      all_orders.sort(function (a, b) {
        return a.rank - b.rank;
      });

      all_orders.map((order) => {
        // let internalId = rows[rows.length - 1] && rows[rows.length - 1][0] == order.internalId ? true : false;
        // To check if the ranks are in reverse order within the orders items, need to check the whole list everytime
        let internalId = 0;
        let idx = -1;
        rows.map((r, i) => {
          if (r[0] == order.internalId) {
            internalId = order.internalId;
            idx = i;
          }
        });

        if (internalId > 0) {
          rows[rows.length - 1][5] = (
            Number(rows[rows.length - 1][5]) + Number(order.orderAmount)
          ).toFixed(2);
        } else {
          let row = [];
          row.push(order.internalId); // temporary adding
          row.push(order.rank);
          row.push(order.customerName);
          row.push(order.customerProjectId);
          row.push(order.invoiceNo ? order.invoiceNo : order.saleOrderNo);
          row.push(order.orderAmount.toFixed(2));
          row.push(order.amountReceivedCash ? order.amountReceivedCash.toFixed(2) :  "    ");
          row.push(order.amountReceivedCard ? order.amountReceivedCard.toFixed(2) :  "    ");
          row.push(order.amountReceivedAccount ? order.amountReceivedAccount.toFixed(2) :  "    ");
          // row.push("    ");
          // row.push("    ");
          // row.push("    ");
          rows.push(row);
          row = [];
        }
      });
      // });
      rows.map((row) => {
        row.shift(); // removing the internal ID
      });
      let formatted_date = moment(deliveryDate).format("DD-MMMM-YYYY");
      var driver = routePallet.driver_name;
      var truck = routePallet.registration_number;

      var pageContent = function (data) {
        // HEADER
        pdf.setFontSize(14);
        pdf.text(title, 50, startrow + 10);
        pdf.text("Date: " + formatted_date, 600, startrow + 10);

        pdf.text("Driver : " + driver, 50, startrow + 30);

        pdf.text("Truck: " + truck, 600, startrow + 30);
        pdf.text(
          "Route Description: " +
            routePallet.selectedRoute.title +
            " (" +
            routePallet.selectedRoute.code +
            ")",
          300,
          startrow + 10
        );

        var headerheight = 50;
        //2nd small box

        pdf.setFontSize(30);
        pdf.text("" + routeNo, 740, startrow + 81);
        pdf.setLineWidth(1.0);
        // pdf.line(600, startrow + 54, 790, startrow + 54);
        // pdf.line(600, startrow + 90, 790, startrow + 90);
        // pdf.line(600, startrow + 54, 600, startrow + 90);
        // pdf.line(790, startrow + 54, 790, startrow + 90);
        pdf.rect(700, startrow + 54, 100, 35);

        // Draw Credit Card Terminal Text & box
        pdf.setFontSize(14);
        pdf.text("Credit Card Terminal No.", 340, startrow + 75);
        pdf.rect(500, startrow + 54, 100, 35);


        //below small box
        pdf.setFontSize(14);
        pdf.text("Milage", 50, startrow + 70);
        pdf.text("Refueling", 50, startrow + 90);
        pdf.setFontSize(11);
        pdf.text("Start", 140, startrow + 70);
        pdf.text("End", 225, startrow + 70);
        pdf.text("Liters", 140, startrow + 90);
        pdf.text("Costs", 225, startrow + 90);

        // FOOTER
        var str = "Page " + pageNo;
        // Total page number plugin only available in jspdf v1.0+
        if (typeof pdf.putTotalPages === "function") {
          str = str; // + " of " + totalPagesExp;
        }
        pdf.setFontSize(10);
        var pageHeight =
          pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
        pdf.text(str, data.settings.margin.left, pageHeight - 20); // showing current page number
        pageNo += 1;
      };

      pdf.autoTable(col, rows, {
        didDrawPage: pageContent,
        theme: "grid",
        margin: { top: startrow + 98 },
        styles: {
          fontSize: 8,
          valign: "middle",
          halign: "center",
          align: "center",
        },

        columnStyles: {
          0: { columnWidth: 60 },
          1: { columnWidth: 70 },
          2: { columnWidth: 190 },
          3: { columnWidth: 80 },
          4: { columnWidth: 80 },
          5: { columnWidth: 80 },
        },
      });

      //for adding total number of pages // i.e 10 etc
      if (typeof pdf.putTotalPages === "function") {
        pdf.putTotalPages(totalPagesExp);
      }
      if (routeNo < totalRoutes) {
        pdf.addPage();
      }
      routeNo = routeNo + 1;
    });
    pdf.save(
      moment(deliveryDate).format("DD-MM-YYYY") + "-" + "Driver-Sheet.pdf"
    );
  };

  const printGoodsReturnReport = () => {
    let col = []; // initialization for headers
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var saleorderdate, transportrouteID;
    let title = "Goods Return"; // title of report

    let pdf = new jsPDF("l", "pt", "A4");
    var startrow = 20;
    var totalRoutes = routePallets.length;

    var routeNo = 1;

    routePallets.map((routePallet) => {
      let route = routes.filter((r) => r.id == routePallet.route_status_id);
      if (route.length == 0) {
        return;
      } else {
        routePallet.selectedRoute = route[0];
        routePallet.driver_name = route[0].driver_name;
        routePallet.registration_number = route[0].registration_number;
      }
    });
    routePallets.sort(function (a, b) {
      return a.driver_name.localeCompare(b.driver_name);
    });
    routePallets.map((routePallet) => {
      // let route = routes.filter(r => r.id == routePallet.route_status_id);

      // if (route.length == 0) {
      //     return;
      // }
      // let selectedRoute1 = route[0];

      let rows = [];
      var total_weight = 0.0;
      var previousTotalPages = 0;
      const totalPagesExp = "{total_pages_count_string}";
      let formatted_date = moment(deliveryDate).format("DD-MMMM-YYYY");
      var driver = routePallet.driver_name;
      var truck = routePallet.registration_number;
      var pageContent = function (data) {
        // HEADER
        pdf.text(title, 350, startrow + 5);
        pdf.text("Date: " + formatted_date + "", 320, startrow + 20);
        pdf.setLineWidth(1.5);
        pdf.line(5, startrow + 30, 815, startrow + 30);
        pdf.setFontSize(12);
        // pdf.text("VEHICLE REG:", 80, startrow + 20);
        // pdf.line(170, startrow + 20, 250, startrow + 20);
        // pdf.text("NAME:", 500, startrow + 20);
        // pdf.line(540, startrow + 20, 620, startrow + 20);
        pdf.text("Driver Information", 15, startrow + 50);
        pdf.setLineWidth(1.0);
        pdf.line(15, startrow + 56, 200, startrow + 56);

        pdf.text("Driver :" + driver, 15, startrow + 75);

        pdf.text("Truck Information", 710, startrow + 50);
        pdf.setLineWidth(1.0);
        pdf.line(710, startrow + 56, 830, startrow + 56);
        pdf.text("Truck No: " + truck, 710, startrow + 75);

        //outer Box
        pdf.setLineWidth(2.0);
        pdf.line(15, startrow + 90, 830, startrow + 90);
        pdf.setLineWidth(1.0);
        pdf.line(15, startrow + 90, 15, startrow + 330);
        pdf.line(830, startrow + 90, 830, startrow + 330);

        //inner Vertical Lines
        pdf.line(90, startrow + 90, 90, startrow + 330);
        pdf.line(160, startrow + 90, 160, startrow + 330);
        pdf.line(270, startrow + 90, 270, startrow + 330);
        pdf.line(310, startrow + 90, 310, startrow + 330);
        pdf.line(400, startrow + 90, 400, startrow + 330);
        pdf.line(480, startrow + 90, 480, startrow + 330);
        pdf.line(530, startrow + 90, 530, startrow + 330);
        pdf.line(625, startrow + 90, 625, startrow + 330);
        pdf.line(730, startrow + 90, 730, startrow + 330);

        //inner Horizontal Lines
        pdf.setLineWidth(2.0);
        pdf.line(15, startrow + 120, 830, startrow + 120);
        pdf.setLineWidth(1.0);
        pdf.line(15, startrow + 150, 830, startrow + 150);
        pdf.line(15, startrow + 180, 830, startrow + 180);
        pdf.line(15, startrow + 210, 830, startrow + 210);
        pdf.line(15, startrow + 240, 830, startrow + 240);
        pdf.line(15, startrow + 270, 830, startrow + 270);
        pdf.line(15, startrow + 300, 830, startrow + 300);
        pdf.line(15, startrow + 330, 830, startrow + 330);

        //COLOUMN NAME
        pdf.setFontSize(10);
        pdf.text("Customer Code", 18, startrow + 105);
        pdf.text("Product Code", 95, startrow + 105);
        pdf.text("Product Name", 180, startrow + 105);
        pdf.text("QTY", 280, startrow + 105);
        pdf.text("Reason for Return ", 315, startrow + 105);
        pdf.text("(Code)", 335, startrow + 115);
        pdf.setFontSize(8);
        pdf.text("Returned To Stock", 405, startrow + 100);
        pdf.setFontSize(10);
        pdf.text("(Tick)", 425, startrow + 110);
        pdf.setFontSize(9);
        pdf.text("Discarded", 485, startrow + 100);
        pdf.setFontSize(10);
        pdf.text("(Tick)", 490, startrow + 110);
        pdf.setFontSize(9);
        pdf.text("Receiver Signature", 540, startrow + 105);
        pdf.text("Driver Signature", 640, startrow + 105);
        pdf.text("Supervisor Signature", 740, startrow + 105);

        //Bottom Box1
        //vertical
        pdf.line(90, startrow + 330, 90, startrow + 515);
        pdf.line(245, startrow + 355, 245, startrow + 515);
        pdf.line(300, startrow + 330, 300, startrow + 515);
        //Bottom Box2
        //vertical
        pdf.line(310, startrow + 330, 310, startrow + 435);
        pdf.line(445, startrow + 355, 445, startrow + 435);
        pdf.line(502, startrow + 330, 502, startrow + 435);
        //horizontal box1
        pdf.line(90, startrow + 330, 300, startrow + 330);
        pdf.line(90, startrow + 355, 300, startrow + 355);
        pdf.line(90, startrow + 375, 300, startrow + 375);
        pdf.line(90, startrow + 395, 300, startrow + 395);
        pdf.line(90, startrow + 415, 300, startrow + 415);
        pdf.line(90, startrow + 435, 300, startrow + 435);
        pdf.line(90, startrow + 455, 300, startrow + 455);
        pdf.line(90, startrow + 475, 300, startrow + 475);
        pdf.line(90, startrow + 495, 300, startrow + 495);
        pdf.line(90, startrow + 515, 300, startrow + 515);
        // pdf.line(90, startrow + 535, 300, startrow + 535);

        //horizontal box2
        pdf.line(310, startrow + 355, 502, startrow + 355);
        pdf.line(310, startrow + 375, 502, startrow + 375);
        pdf.line(310, startrow + 395, 502, startrow + 395);
        pdf.line(310, startrow + 415, 502, startrow + 415);
        pdf.line(310, startrow + 435, 502, startrow + 435);

        pdf.setFontSize(15);
        pdf.text("Physical Stock Return", 120, startrow + 350);
        pdf.text("Non Physical Stock Return", 315, startrow + 350);

        pdf.setFontSize(13);

        pdf.text("Reason", 120, startrow + 370);
        pdf.text("Code", 255, startrow + 370);

        pdf.text("Reason", 330, startrow + 370);
        pdf.text("Code", 450, startrow + 370);

        // Box1
        pdf.setFontSize(10);
        pdf.text("Pricing Error", 120, startrow + 390);
        pdf.text("Invoice Error", 120, startrow + 410);
        pdf.text("Damaged In Transit", 120, startrow + 430);
        pdf.text("Customer Over Ordered", 120, startrow + 450);
        pdf.text("Customer Returned", 120, startrow + 470);
        pdf.text("Return Form", 120, startrow + 490);
        pdf.text("Full Delievery Return", 120, startrow + 510);
        // pdf.text("Shortage / Loading Error", 120, startrow + 530);
        // pdf.text("Out of Stock", 120, startrow + 550);

        // Box2
        pdf.text("Delivery Fee Discount", 330, startrow + 390);
        pdf.text("Item Discount", 330, startrow + 410);
        pdf.text("Shortage", 330, startrow + 430);

        // Box1
        pdf.text("PE", 260, startrow + 390);
        pdf.text("IE", 260, startrow + 410);
        pdf.text("DIT", 260, startrow + 430);
        pdf.text("COO", 260, startrow + 450);
        pdf.text("CR", 260, startrow + 470);
        pdf.text("RFR", 260, startrow + 490);
        pdf.text("FDR", 260, startrow + 510);
        // pdf.text("S", 260, startrow + 530);
        // pdf.text("OS", 260, startrow + 550);

        // Box2
        pdf.text("DD", 450, startrow + 390);
        pdf.text("ID", 450, startrow + 410);
        pdf.text("S", 450, startrow + 430);

        // //Bottom Box2
        // //vertical
        // pdf.line(350, startrow + 400, 350, startrow + 520);
        // pdf.line(480, startrow + 400, 480, startrow + 520);
        // pdf.line(830, startrow + 400, 830, startrow + 520);

        // //Horizontal
        // pdf.line(350, startrow + 400, 830, startrow + 400);
        // pdf.line(350, startrow + 420, 830, startrow + 420);
        // pdf.line(350, startrow + 440, 830, startrow + 440);
        // pdf.line(350, startrow + 460, 830, startrow + 460);
        // pdf.line(350, startrow + 480, 830, startrow + 480);
        // pdf.line(350, startrow + 500, 830, startrow + 500);
        // pdf.line(350, startrow + 520, 830, startrow + 520);
        // //Text
        // pdf.setFontSize(12);
        // pdf.text("Diesel", 355, startrow + 415);
        // pdf.text("Adblue", 355, startrow + 435);
        // pdf.text("Fridge Tank", 355, startrow + 455);
        // pdf.text("Damages", 355, startrow + 475);
        // pdf.text("Trolley Inspection", 355, startrow + 495);
        // pdf.text("Fuel Card", 355, startrow + 515);

        //WAREHOUSE SIGNATURE
        pdf.setFontSize(12);

        pdf.text("Receiver Name:", 600, startrow + 370);
        pdf.line(690, startrow + 370, 830, startrow + 370);

        pdf.text("Receiver Signature:", 600, startrow + 410);
        pdf.line(710, startrow + 410, 830, startrow + 410);

        pdf.text("Warehouse Manager Name:", 550, startrow + 510);
        pdf.line(710, startrow + 510, 830, startrow + 510);

        pdf.text("Warehouse Manager Signature:", 550, startrow + 550);
        pdf.line(720, startrow + 550, 830, startrow + 550);

        // FOOTER
        var str = "Page " + data.pageCount;
        // Total page number plugin only available in jspdf v1.0+
        if (typeof pdf.putTotalPages === "function") {
          str = str; // + " of " + totalPagesExp;
        }
        pdf.setFontSize(10);
        var pageHeight =
          pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
        pdf.text(str, data.settings.margin.left, pageHeight - 20); // showing current page number
      };

      pdf.autoTable(col, rows, {
        didDrawPage: pageContent,
        theme: "grid",
        margin: {
          top: startrow + 130,
          bottom: startrow + 190,
          down: startrow + 600,
        },
        styles: {
          fontSize: 8,
          valign: "middle",
          halign: "center",
          align: "center",
        },
        columnStyles: {
          0: { columnWidth: 65 },
          1: { columnWidth: 120 },
          2: { columnWidth: 40 },
          3: { columnWidth: 40 },
          4: { columnWidth: 140 },
          5: { columnWidth: 45 },
          6: { columnWidth: 45 },
          7: { columnWidth: 140 },
          8: { columnWidth: 140 },
        },
      });

      //for adding total number of pages // i.e 10 etc
      if (typeof pdf.putTotalPages === "function") {
        pdf.putTotalPages(totalPagesExp);
      }
      if (routeNo < totalRoutes) {
        pdf.addPage();
      }
      routeNo = routeNo + 1;
    });
    pdf.save(
      moment(deliveryDate).format("DD-MM-YYYY") + "-" + "Goods-Return.pdf"
    );
  };

  const printCheckListReport = () => {
    let col = []; // initialization for headers
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var saleorderdate, transportrouteID;
    let title = "Inbound/Outbound CheckList"; // title of report
    let pdf = new jsPDF("l", "pt", "A4");
    var startrow = 15;
    var totalRoutes = routePallets.length;
    var routeNo = 1;

    routePallets.map((routePallet) => {
      let route = routes.filter((r) => r.id == routePallet.route_status_id);
      if (route.length == 0) {
        return;
      } else {
        routePallet.selectedRoute = route[0];
        routePallet.driver_name = route[0].driver_name;
        routePallet.registration_number = route[0].registration_number;
      }
    });

    routePallets.sort(function (a, b) {
      return a.driver_name.localeCompare(b.driver_name);
    });

    routePallets.map((routePallet) => {
      // let route = routes.filter(r => r.id == routePallet.route_status_id);

      // if (route.length == 0) {
      //     return;
      // }
      // let selectedRoute1 = route[0];

      let rows = [];
      var total_weight = 0.0;
      var previousTotalPages = 0;
      const totalPagesExp = "{total_pages_count_string}";

      let formatted_date = moment(deliveryDate).format("DD-MMMM-YYYY");
      var driver = routePallet.driver_name;
      var truck = routePallet.registration_number;

      var pageContent = function (data) {
        // HEADER
        pdf.text(title, 300, startrow + 15);
        pdf.text("Date: " + formatted_date + "", 320, startrow + 30); // 450 here is x-axis and 80 is y-axis
        pdf.setLineWidth(1.5);
        pdf.line(12, startrow + 35, 830, startrow + 35);
        pdf.setFontSize(12);
        pdf.text("Driver : " + driver, 12, startrow + 55); // 450 here is x-axis and 80 is y-axis
        pdf.setLineWidth(1.0);
        pdf.line(12, startrow + 65, 200, startrow + 65);
        pdf.text("Outbound Mileage Reading : ", 12, startrow + 106); // 450 here is x-axis and 80 is y-axis\
        pdf.line(170, startrow + 106, 290, startrow + 106);
        pdf.text("Truck Reg : " + truck, 700, startrow + 55);
        pdf.setLineWidth(1.0);
        pdf.line(700, startrow + 61, 830, startrow + 61);
        pdf.text(
          "Route Description: " + routePallet.selectedRoute.title,
          320,
          startrow + 101
        );
        pdf.setLineWidth(1.0);
        // pdf.line(320, startrow + 106, 485, startrow + 106);
        //=========routeNo
        pdf.setFontSize(30);
        pdf.text("" + routeNo, 740, startrow + 97);
        // =================Small Box  ===========
        pdf.setLineWidth(1.0);
        pdf.line(670, startrow + 70, 830, startrow + 70);
        pdf.line(670, startrow + 106, 830, startrow + 106);
        pdf.line(670, startrow + 70, 670, startrow + 106);
        pdf.line(830, startrow + 70, 830, startrow + 106);

        // 450 here is x-axis and 80 is y-axis

        //outer Box
        pdf.setLineWidth(1.5);
        pdf.line(114.5, startrow + 115, 830, startrow + 115);
        pdf.setLineWidth(1.5);
        pdf.line(12, startrow + 149, 12, startrow + 290);
        pdf.setLineWidth(1.0);
        pdf.line(830, startrow + 115, 830, startrow + 290);

        //inner Vertical Lines
        pdf.line(115, startrow + 115, 115, startrow + 290);
        pdf.line(170, startrow + 115, 170, startrow + 290);
        pdf.line(225, startrow + 115, 225, startrow + 290);
        pdf.line(280, startrow + 115, 280, startrow + 290);
        pdf.line(335, startrow + 115, 335, startrow + 290);
        pdf.line(390, startrow + 115, 390, startrow + 290);
        pdf.line(445, startrow + 115, 445, startrow + 290);
        pdf.line(500, startrow + 115, 500, startrow + 290);
        //====================Start inner table for Fuel Check column===================
        pdf.line(528, startrow + 150, 528, startrow + 290);
        pdf.line(500, startrow + 168, 555, startrow + 168);
        pdf.line(500, startrow + 186, 555, startrow + 186);
        pdf.line(500, startrow + 204, 555, startrow + 204);
        pdf.line(500, startrow + 236, 555, startrow + 236);
        pdf.line(500, startrow + 255, 555, startrow + 255);
        pdf.line(500, startrow + 274, 555, startrow + 274);
        //==================== End inner table for Fuel Check=========================
        // ============ Start   Inner Coloumn for Fuel Card=============
        // pdf.line(593, startrow + 186, 593, startrow + 220);
        // pdf.line(555, startrow + 186, 610, startrow + 186);
        pdf.line(593, startrow + 220, 593, startrow + 290);
        pdf.line(555, startrow + 255, 610, startrow + 255);
        // ================== End  Inner Coloumn for Fuel Card=============
        pdf.line(555, startrow + 115, 555, startrow + 290);
        pdf.line(610, startrow + 115, 610, startrow + 290);
        pdf.line(665, startrow + 115, 665, startrow + 290);
        pdf.line(720, startrow + 115, 720, startrow + 290);
        //====================Start inner table Freezer Fuel column===================
        pdf.line(693, startrow + 150, 693, startrow + 290);
        pdf.line(665, startrow + 168, 720, startrow + 168);
        pdf.line(665, startrow + 186, 720, startrow + 186);
        pdf.line(665, startrow + 204, 720, startrow + 204);
        pdf.line(665, startrow + 236, 720, startrow + 236);
        pdf.line(665, startrow + 255, 720, startrow + 255);
        pdf.line(665, startrow + 274, 720, startrow + 274);
        //==================== End inner table for Freezer Fuel =========================
        //inner Horizontal Lines
        pdf.setLineWidth(1.5);
        pdf.line(12, startrow + 150, 830, startrow + 150);
        pdf.setLineWidth(1.0);
        pdf.line(12, startrow + 220, 830, startrow + 220);
        pdf.line(12, startrow + 290, 830, startrow + 290);
        //COLOUMN NAME
        pdf.setFontSize(10);
        pdf.text("OUTBOUND", 35, startrow + 190);
        pdf.setFontSize(16);
        pdf.setFontSize(10);
        pdf.text("INBOUND", 35, startrow + 260);
        pdf.setFontSize(16);
        pdf.setFontSize(10);
        pdf.text("Defect", 120, startrow + 132);
        pdf.text("Sheet", 120, startrow + 142);
        //==================NA======================
        pdf.setFontSize(14);
        pdf.text("NA", 130, startrow + 255);
        //==================NA======================
        pdf.setFontSize(10);
        pdf.text("Trolley", 180, startrow + 137);
        pdf.text("Pump ", 240, startrow + 132);
        pdf.text("Truck", 240, startrow + 142);
        pdf.text("Driver ", 290, startrow + 132);
        pdf.text("Manifest", 290, startrow + 142);
        pdf.text("Card ", 345, startrow + 132);
        pdf.text("Terminal", 345, startrow + 142);
        pdf.text("Techo", 395, startrow + 132);
        pdf.text("Uplolad", 395, startrow + 142);
        //==================NA======================
        pdf.setFontSize(14);
        pdf.text("NA", 405, startrow + 255);
        pdf.setFontSize(10);
        //==================NA======================
        pdf.text("Key", 460, startrow + 137);
        pdf.text("Fuel ", 510, startrow + 132);
        pdf.text("Check", 510, startrow + 142);
        //====================Start Inner Fuel Check Table Field==================
        pdf.text("F", 508, startrow + 162);
        pdf.text("3/4", 508, startrow + 180);
        pdf.text("1/2", 508, startrow + 198);
        pdf.text("1/4", 508, startrow + 215);
        // ===========================2nd Column =====================
        pdf.text("F", 508, startrow + 232);
        pdf.text("3/4", 508, startrow + 249);
        pdf.text("1/2", 508, startrow + 267);
        pdf.text("1/4", 508, startrow + 285);
        //====================End Inner Fuel Check Table Field==================
        pdf.text("Fuel ", 565, startrow + 132);
        pdf.text("Card", 565, startrow + 142);
        //================= Start Fuel Card Value=======================
        // pdf.text("Fuel ", 557, startrow + 167);
        // pdf.text("Card", 557, startrow + 177);
        // pdf.text("Fuel ", 557, startrow + 200);
        // pdf.text("Receipt", 557, startrow + 210);

        pdf.text("Fuel ", 557, startrow + 237);
        pdf.text("Card", 557, startrow + 247);
        pdf.text("Fuel ", 557, startrow + 267);
        pdf.text("Receipt", 557, startrow + 277);
        //================= Start Fuel Card Value=======================
        pdf.text("Ad Blue", 620, startrow + 132);
        pdf.text("Check", 620, startrow + 142);
        //==================NA======================
        pdf.setFontSize(14);
        pdf.text("NA", 630, startrow + 255);
        pdf.setFontSize(10);
        //==================NA======================
        pdf.text("Freezer", 675, startrow + 132);
        pdf.text("Fuel", 675, startrow + 142);
        //====================Start Inner Freezer Fuel  Table Field==================
        pdf.text("F", 672, startrow + 162);
        pdf.text("3/4", 672, startrow + 180);

        pdf.text("1/2", 672, startrow + 198);
        pdf.text("1/4", 672, startrow + 215);
        // ===========================2nd Column =====================
        pdf.text("F", 672, startrow + 232);
        pdf.text("3/4", 672, startrow + 249);
        pdf.text("1/2", 672, startrow + 267);
        pdf.text("1/4", 672, startrow + 285);
        //====================End Inner Freezer Fuel  Table Field==================
        pdf.text("Driver Signature", 730, startrow + 137);
        pdf.setFontSize(12);
        //=======================""===============//
        pdf.text("Inbound Mileage Reading : ", 12, startrow + 320);
        pdf.line(160, startrow + 320, 260, startrow + 320);

        //===============Images============
        var imgData = routePallet.selectedRoute.vehicle_type === 1 ? Van : Truck;
        var format = 'JPEG';
        var imgX = 12;
        var imgY = startrow + 340;
        var imgWidth = 540; 
        var imgHeight = 215;
        pdf.addImage(imgData, format, imgX, imgY, imgWidth, imgHeight);
        //===============Image-End============

        //WAREHOUSE SIGNATURE
        pdf.setFontSize(12);
        pdf.text("Outbounder Name:", 580, startrow + 370);
        pdf.line(690, startrow + 370, 830, startrow + 370);
        pdf.text("Outbounder Signature:", 580, startrow + 420);
        pdf.line(710, startrow + 420, 830, startrow + 420);
        pdf.text("Inbounder Name:", 580, startrow + 500);
        pdf.line(680, startrow + 500, 830, startrow + 500);
        pdf.text("Inbounder Signature:", 580, startrow + 550);
        pdf.line(700, startrow + 550, 830, startrow + 550);

        // FOOTER
        var str = "Page " + data.pageCount;
        // Total page number plugin only available in jspdf v1.0+
        if (typeof pdf.putTotalPages === "function") {
          str = str; // + " of " + totalPagesExp;
        }
        pdf.setFontSize(10);
        var pageHeight =
          pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
          pdf.text(str, data.settings.margin.left - 20 , pageHeight - 20); // showing current page number
      };

      pdf.autoTable(col, rows, {
        didDrawPage: pageContent,
        theme: "grid",
        margin: {
          top: startrow + 130,
          bottom: startrow + 190,
          down: startrow + 600,
        },
        styles: {
          fontSize: 8,
          valign: "middle",
          halign: "center",
          align: "center",
        },
        columnStyles: {
          0: { columnWidth: 65 },
          1: { columnWidth: 120 },
          2: { columnWidth: 40 },
          3: { columnWidth: 40 },
          4: { columnWidth: 140 },
          5: { columnWidth: 45 },
          6: { columnWidth: 45 },
          7: { columnWidth: 140 },
          8: { columnWidth: 140 },
        },
      });

      //for adding total number of pages // i.e 10 etc
      if (typeof pdf.putTotalPages === "function") {
        pdf.putTotalPages(totalPagesExp);
      }
      if (routeNo < totalRoutes) {
        pdf.addPage();
      }
      routeNo = routeNo + 1;
    });
    pdf.save(moment(deliveryDate).format("DD-MM-YYYY") + "-" + "CheckList.pdf");
  };

  const printPickListReport = () => {
    let col = ["Drop No", "Name", "Description", "QTY"]; // initialization for headers

    let title = "Pick List"; // title of report

    let pdf = new jsPDF("p", "pt", "A4");
    var startrow = 20;
    var totalRoutes = routePallets.length;

    var routeNo = 1;
    var pageNo = 1;

    routePallets.map((routePallet) => {
      let route = routes.filter((r) => r.id == routePallet.route_status_id);

      if (route.length == 0) {
        return;
      } else {
        routePallet.selectedRoute = route[0];
        routePallet.driver_name = route[0].driver_name;
        routePallet.registration_number = route[0].registration_number;
      }
    });

    routePallets.sort(function (a, b) {
      return a.driver_name.localeCompare(b.driver_name);
    });

    routePallets.map((routePallet) => {
      // let route = routes.filter(r => r.id == routePallet.route_status_id);

      // if (route.length == 0) {
      //     return;
      // }
      // let selectedRoute1 = route[0];

      let rows = [];
      var SeqNo = 0;
      var total_weight = 0.0;
      var previousTotalPages = 0;
      const totalPagesExp = "{total_pages_count_string}";
      // orderinformationRoutewise[x].sort((a, b) => (a.saleorder_ID.transportroute_SEQUENCENO < b.saleorder_ID.transportroute_SEQUENCENO) ? 1 : -1);
      routePallet.pallets.map((pallet) => {
        pallet.items.map((order) => {
          order.items.map((item) => {
            if (item.itemId != "Delivery Charge") {
              let row = [];
              if (SeqNo == 0 || SeqNo != order.rank) {
                row.push(order.rank);
                row.push(order.customerProjectId);
              } else {
                row.push("");
                row.push("");
              }
              SeqNo = order.rank;
              row.push(item.itemName);
              row.push(item.quantity);
              rows.push(row);
            }
          });
        });
      });

      let formatted_date = moment(deliveryDate).format("DD-MMMM-YYYY");
      var truck = routePallet.registration_number;

      var pageContent = function (data) {
        // HEADER
        pdf.text(title, 250, startrow + 10);
        pdf.setFontSize(12);
        pdf.setLineWidth(1.0);

        pdf.text("Truck No: " + truck, 50, startrow + 25);

        pdf.text(" " + formatted_date + " ", 430, startrow + 25);
        pdf.line(5, startrow + 35, 590, startrow + 35);

        // FOOTER
        var str = "Page " + pageNo; // data.pageCount;
        // Total page number plugin only available in jspdf v1.0+
        if (typeof pdf.putTotalPages === "function") {
          str = str; // + " of " + totalPagesExp;
        }
        pdf.setFontSize(10);
        var pageHeight =
          pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
        pdf.text(str, data.settings.margin.left, pageHeight - 20); // showing current page number
        pageNo += 1;
      };

      pdf.autoTable(col, rows, {
        didDrawPage: pageContent,
        theme: "grid",
        margin: {
          top: startrow + 45,
          left: 15,
        },
        styles: {
          fontSize: 8,
          valign: "middle",
          halign: "center",
          align: "center",
        },
        columnStyles: {
          0: { columnWidth: 50 },
          1: {
            columnWidth: 230,
            valign: "left",
            halign: "left",
            align: "left",
          },
          2: {
            columnWidth: 240,
            valign: "left",
            halign: "left",
            align: "left",
          },
          3: { columnWidth: 40 },
        },
      });

      //for adding total number of pages // i.e 10 etc
      if (typeof pdf.putTotalPages === "function") {
        pdf.putTotalPages(totalPagesExp);
      }
      if (routeNo < totalRoutes) {
        pdf.addPage();
      }
      routeNo = routeNo + 1;
    });
    pdf.save(moment(deliveryDate).format("DD-MM-YYYY") + "-PickList.pdf");
  };

  const printFinancialSummary = () => {
    let col = [
      "Driver Name",
      "Expected Total",
      "Truck Reg",
      "Route Name",
      "Route Code",
      "S. No.",
      "Cash Recieved",
    ]; // initialization for headers
    let col2 = ["Pay Slip Number", "Banked", "Difference"]; // initialization for headers

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var saleorderdate,
      transportrouteID,
      overall_amount = 0.0;
    let title = "Financial Summary"; // title of report

    let pdf = new jsPDF("l", "pt", "A4");
    var startrow = 20;
    var totalRoutes = routePallets.length;
    var routeNo = 1;

    let rowD = [];
    let rowD2 = [];

    routePallets.map((routePallet, rIdx) => {
      // if (rIdx != '0')
      {
        let row = [];
        let row2 = [];
        var total_amount = 0.0;
        routePallet.pallets.map((pallet) => {
          pallet.items.map((order) => {
            total_amount += Number(order.orderAmount);
            // order.items.map((item) => {
            //     total_amount += (Number(item.quantity) * Number(item.unitPrice));
            // })
          });
        });

        var totalamount = total_amount.toFixed(2);
        overall_amount = overall_amount + total_amount;
        let rSelected = routes.filter(
          (r) => r.id == routePallet.route_status_id
        );

        if (rSelected.length > 0) {
          var i;
          var driverinfo = rSelected[0].driver_name;
          var truckinfo = rSelected[0].registration_number;
          var routecodeinfo = rSelected[0].code;
          var routedescinfo = rSelected[0].title;

          //
          row.push(driverinfo);
          row.push(totalamount);
          row.push(truckinfo);
          row.push(routedescinfo);
          row.push(routecodeinfo);
          row.push(routeNo);
          row.push("");
          rowD.push(row);
          row = [];

          if (routeNo <= 10) {
            row2.push("");
            row2.push("");
            row2.push("");
            rowD2.push(row2);
            row2 = [];
          }
          routeNo = routeNo + 1;
        }
      }
    });
    var overallamount = overall_amount.toFixed(2);
    let row = [];
    let row2 = [];
    row.push("Front Desk");
    row.push("");
    row.push("");
    row.push("");
    row.push("");
    rowD.push(row);
    row = [];
    row.push("");
    row.push("");
    row.push("");
    row.push("");
    row.push("");
    rowD.push(row);
    row = [];
    row.push("");
    row.push("");
    row.push("");
    row.push("");
    row.push("");
    rowD.push(row);
    row = [];
    row.push("Grand Total");
    row.push(overallamount);
    row.push("");
    row.push("");
    row.push("");
    row.push("");
    row.push("");
    rowD.push(row);
    row = [];
    row2.push("Total");
    row2.push("");
    row2.push("");
    rowD2.push(row2);
    row2 = [];
    let formatted_date = moment(deliveryDate).format("DD-MMMM-YYYY");
    //        const totalPagesExp = "{total_pages_count_string}";
    for (var page = 1; page <= 2; page++) {
      var pageContent = function (data) {
        // HEADER
        pdf.text(title, 330, startrow + 10);
        pdf.setFontSize(12);
        pdf.setLineWidth(1.0);

        pdf.text(" " + formatted_date + " ", 350, startrow + 25);
        pdf.line(12, startrow + 35, 825, startrow + 35);

        // FOOTER
        var str = "Page " + pdf.internal.getCurrentPageInfo().pageNumber;
        // Total page number plugin only available in jspdf v1.0+
        if (typeof pdf.putTotalPages === "function") {
          str = str; // + " of " + totalPagesExp;
        }
        pdf.setFontSize(10);
        var pageHeight =
          pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
        pdf.text(str, data.settings.margin.left, pageHeight - 20); // showing current page number
      };
      if (page == 1) {
        pdf.autoTable(col, rowD, {
          didDrawPage: pageContent,
          theme: "grid",
          margin: {
            top: startrow + 45,
            left: 15,
          },
          styles: {
            fontSize: 8,
            valign: "middle",
            halign: "center",
            align: "center",
          },
          columnStyles: {
            0: { columnWidth: 250 },
            1: { columnWidth: 90 },
            2: { columnWidth: 80 },
            3: { columnWidth: 90 },
            4: { columnWidth: 90 },
            5: { columnWidth: 60, fontStyle: "bold", fontSize: 12 },
            6: { columnWidth: 150 },
          },
        });
      } else if (page == 2) {
        pdf.setLineWidth(2.0);
        pdf.line(555, startrow + 350, 820, startrow + 350);
        pdf.line(555, startrow + 450, 820, startrow + 450);
        pdf.line(555, startrow + 350, 555, startrow + 450);
        pdf.line(820, startrow + 350, 820, startrow + 450);
        pdf.setFontSize(16);
        pdf.text("Total Difference", 430, startrow + 410);

        pdf.autoTable(col2, rowD2, {
          didDrawPage: pageContent,
          theme: "grid",
          margin: {
            top: startrow + 45,
            bottom: startrow + 180,
            left: 15,
          },
          styles: {
            fontSize: 12,
            valign: "middle",
            halign: "center",
            align: "center",
          },
          columnStyles: {
            0: { columnWidth: 270 },
            1: { columnWidth: 270 },
            2: { columnWidth: 270 },
          },
        });
      }

      //for adding total number of pages // i.e 10 etc
      // if (typeof pdf.putTotalPages === 'function')
      // {
      //     pdf.putTotalPages(pdf.internal.getCurrentPageInfo().pageNumber);
      // }

      if (page <= 1) {
        pdf.addPage();
      }
    }
    pdf.save(
      moment(deliveryDate).format("DD-MM-YYYY") + "-Financial-Summary.pdf"
    );
  };

  const printFinancialSummaryExcel = () => {
    routePallets.map((routePallet, rIdx) => {
      // if (rIdx != '0')
      {
        let row = [];
        let row2 = [];
        var total_amount = 0.0;
        routePallet.pallets.map((pallet) => {
          pallet.items.map((order) => {
            total_amount += Number(order.orderAmount);
            // order.items.map((item) => {
            //     total_amount += (Number(item.quantity) * Number(item.unitPrice));
            // })
          });
        });
      }
    });

    setType("success");
    setLoadMessage("Downloading financial Summary");
    Auth.fetch(
      `/route/download-financial-summary?delivery_date=${moment(
        deliveryDate
      ).format("YYYY-MM-DD")}`
    )
      .then((res) => {
        if (res.ack) {
          console.log(res.file_url);
          window.open(res.file_url, "_blank", "noreferrer");
        } else {
          setType("error");
          setLoadMessage("Financial summary print failed");
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return;
    let col = [
      "Driver Name",
      "Expected Total",
      "Truck Reg",
      "Route Name",
      "Route Code",
      "S. No.",
      "Cash Recieved",
    ]; // initialization for headers
    let col2 = ["Pay Slip Number", "Banked", "Difference"]; // initialization for headers

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var saleorderdate,
      transportrouteID,
      overall_amount = 0.0;
    let title = "Financial Summary"; // title of report

    let pdf = new jsPDF("l", "pt", "A4");
    var startrow = 20;
    var totalRoutes = routePallets.length;
    var routeNo = 1;

    let rowD = [];
    let rowD2 = [];

    routePallets.map((routePallet, rIdx) => {
      // if (rIdx != '0')
      {
        let row = [];
        let row2 = [];
        var total_amount = 0.0;
        routePallet.pallets.map((pallet) => {
          pallet.items.map((order) => {
            total_amount += Number(order.orderAmount);
            // order.items.map((item) => {
            //     total_amount += (Number(item.quantity) * Number(item.unitPrice));
            // })
          });
        });

        var totalamount = total_amount.toFixed(2);
        overall_amount = overall_amount + total_amount;
        let rSelected = routes.filter(
          (r) => r.id == routePallet.route_status_id
        );

        if (rSelected.length > 0) {
          var i;
          var driverinfo = rSelected[0].driver_name;
          var truckinfo = rSelected[0].registration_number;
          var routecodeinfo = rSelected[0].code;
          var routedescinfo = rSelected[0].title;

          //
          row.push(driverinfo);
          row.push(totalamount);
          row.push(truckinfo);
          row.push(routedescinfo);
          row.push(routecodeinfo);
          row.push(routeNo);
          row.push("");
          rowD.push(row);
          row = [];

          if (routeNo <= 10) {
            row2.push("");
            row2.push("");
            row2.push("");
            rowD2.push(row2);
            row2 = [];
          }
          routeNo = routeNo + 1;
        }
      }
    });
    var overallamount = overall_amount.toFixed(2);
    let row = [];
    let row2 = [];
    row.push("Front Desk");
    row.push("");
    row.push("");
    row.push("");
    row.push("");
    rowD.push(row);
    row = [];
    row.push("");
    row.push("");
    row.push("");
    row.push("");
    row.push("");
    rowD.push(row);
    row = [];
    row.push("");
    row.push("");
    row.push("");
    row.push("");
    row.push("");
    rowD.push(row);
    row = [];
    row.push("Grand Total");
    row.push(overallamount);
    row.push("");
    row.push("");
    row.push("");
    row.push("");
    row.push("");
    rowD.push(row);
    row = [];
    row2.push("Total");
    row2.push("");
    row2.push("");
    rowD2.push(row2);
    row2 = [];
    let formatted_date = moment(deliveryDate).format("DD-MMMM-YYYY");
    //        const totalPagesExp = "{total_pages_count_string}";
    for (var page = 1; page <= 2; page++) {
      var pageContent = function (data) {
        // HEADER
        pdf.text(title, 330, startrow + 10);
        pdf.setFontSize(12);
        pdf.setLineWidth(1.0);

        pdf.text(" " + formatted_date + " ", 350, startrow + 25);
        pdf.line(12, startrow + 35, 825, startrow + 35);

        // FOOTER
        var str = "Page " + pdf.internal.getCurrentPageInfo().pageNumber;
        // Total page number plugin only available in jspdf v1.0+
        if (typeof pdf.putTotalPages === "function") {
          str = str; // + " of " + totalPagesExp;
        }
        pdf.setFontSize(10);
        var pageHeight =
          pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
        pdf.text(str, data.settings.margin.left, pageHeight - 20); // showing current page number
      };
      if (page == 1) {
        pdf.autoTable(col, rowD, {
          didDrawPage: pageContent,
          theme: "grid",
          margin: {
            top: startrow + 45,
            left: 15,
          },
          styles: {
            fontSize: 8,
            valign: "middle",
            halign: "center",
            align: "center",
          },
          columnStyles: {
            0: { columnWidth: 250 },
            1: { columnWidth: 90 },
            2: { columnWidth: 80 },
            3: { columnWidth: 90 },
            4: { columnWidth: 90 },
            5: { columnWidth: 60, fontStyle: "bold", fontSize: 12 },
            6: { columnWidth: 150 },
          },
        });
      } else if (page == 2) {
        pdf.setLineWidth(2.0);
        pdf.line(555, startrow + 350, 820, startrow + 350);
        pdf.line(555, startrow + 450, 820, startrow + 450);
        pdf.line(555, startrow + 350, 555, startrow + 450);
        pdf.line(820, startrow + 350, 820, startrow + 450);
        pdf.setFontSize(16);
        pdf.text("Total Difference", 430, startrow + 410);

        pdf.autoTable(col2, rowD2, {
          didDrawPage: pageContent,
          theme: "grid",
          margin: {
            top: startrow + 45,
            bottom: startrow + 180,
            left: 15,
          },
          styles: {
            fontSize: 12,
            valign: "middle",
            halign: "center",
            align: "center",
          },
          columnStyles: {
            0: { columnWidth: 270 },
            1: { columnWidth: 270 },
            2: { columnWidth: 270 },
          },
        });
      }

      //for adding total number of pages // i.e 10 etc
      // if (typeof pdf.putTotalPages === 'function')
      // {
      //     pdf.putTotalPages(pdf.internal.getCurrentPageInfo().pageNumber);
      // }

      if (page <= 1) {
        pdf.addPage();
      }
    }
    pdf.save(
      moment(deliveryDate).format("DD-MM-YYYY") + "-Financial-Summary.pdf"
    );
  };

  const printRoutePickingAndPacking = () => {
    let col = [
      "S. No.",
      "Tuck",
      "Route",
      "Ambient Picked By",
      "Frozen Picked By",
    ];

    let pdf = new jsPDF("l", "pt", "A4");
    var startrow = 20;
    var totalRoutes = routePallets.length;

    var routeNo = 1;
    var pageNo = 1;

    routePallets.map((routePallet) => {
      let route = routes.filter((r) => r.id == routePallet.route_status_id);

      if (route.length == 0) {
        return;
      } else {
        routePallet.selectedRoute = route[0];
        routePallet.driver_name = route[0].driver_name;
        routePallet.registration_number = route[0].registration_number;
      }
    });

    routePallets.sort(function (a, b) {
      return a.driver_name.localeCompare(b.driver_name);
    });
    pdf.text(moment(deliveryDate).format("DD-MMM-YYYY"), 350, startrow + 10);
    pdf.text("Truck Picking & Packing", 320, startrow + 30);

    pdf.text("Approved By: ", 600, startrow + 25);
    pdf.text("Signature: ", 600, startrow + 50);
    pdf.line(680, startrow + 50, 800, startrow + 50);

    // FOOTER
    var str = "Page " + pageNo;
    // Total page number plugin only available in jspdf v1.0+
    if (typeof pdf.putTotalPages === "function") {
      str = str; // + " of " + totalPagesExp;
    }
    pdf.setFontSize(10);
    var pageHeight =
      pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
    pdf.text(str, 20, pageHeight - 20); // showing current page number

    pageNo += 1;
    let rows1 = [];

    routePallets.map((routePallet, idx) => {
      let row1 = [];
      row1.push(Number(idx) + 1);
      row1.push(routePallet.registration_number);
      row1.push(routePallet.selectedRoute.title);
      row1.push("");
      row1.push("");
      row1.push("");
      row1.push("");
      rows1.push(row1);
    });
    pdf.autoTable(col, rows1, {
      theme: "grid",
      margin: { top: startrow + 60 },
      styles: {
        fontSize: 8,
        valign: "middle",
        halign: "center",
        align: "center",
      },
      columnStyles: {
        0: { columnWidth: 40 },
        1: { columnWidth: 70 },
        2: { columnWidth: 90 },
        3: { columnWidth: 280 },
        4: { columnWidth: 280 },
      },
    });
    //for adding total number of pages // i.e 10 etc
    // if (typeof pdf.putTotalPages === 'function') {
    //     pdf.putTotalPages(totalPagesExp);
    // }
    // if (routeNo < totalRoutes) {
    //     pdf.addPage();
    // }
    routeNo = routeNo + 1;

    pdf.save(
      moment(deliveryDate).format("DD-MM-YYYY") + "-" + "Picking-Packing.pdf"
    );
  };

  const printStockByTruckAllRoutes = () => {
    let col = ["Item Code", "Description", "Truck No", "QTY"]; // initialization for headers
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var saleorderdate, transportrouteID;
    let title = "Stock by Truck"; // title of report

    let pdf = new jsPDF("p", "pt", "A4");
    var startrow = 20;

    let rowD = [];
    const totalPagesExp = "{total_pages_count_string}";
    var ProductID = 0;
    var ProductQTY = 0;
    var truckQTY = 0;
    var truck;
    var totalRoutes = routePallets.length;

    var routeNo = 1;
    var pageNo = 1;

    var orderinformationRoutewise = [];

    // console.log(routes)

    routePallets.map((routePallet) => {
      let route = routes.filter((r) => r.id == routePallet.route_status_id);

      if (route.length == 0) {
        return;
      } else {
        routePallet.selectedRoute = route[0];
        routePallet.driver_name = route[0].driver_name;
        routePallet.registration_number = route[0].registration_number;
      }
    });

    routePallets.sort(function (a, b) {
      return a.driver_name.localeCompare(b.driver_name);
    });

    routePallets.map((routePallet) => {
      // console.log(routes)
      // console.log('Route ID:', routePallet.route_status_id)
      let sRoute = routes.filter((r) => r.id == routePallet.route_status_id);
      // let routeSelected = {};
      // if (sRoute.length > 0)
      //     routeSelected = sRoute[0];

      // console.log(routeSelected)
      routePallet.pallets.map((pallet) => {
        pallet.items.map((order) => {
          order.items.sort(function (a, b) {
            return a.itemId.localeCompare(b.itemId, "en", { numeric: true });
          });

          order.items.map((item) => {
            if (item.itemId != "Delivery Charge") {
              let checkItem = orderinformationRoutewise.filter(
                (o) => o.itemId == item.itemId
              );

              if (checkItem.length > 0) {
                let checkTruck = checkItem[0].truckQty.filter(
                  (t) => t.truck == routePallet.registration_number
                );
                if (checkTruck[0]) {
                  checkTruck[0].quantity += Number(item.quantity);
                } else {
                  checkItem[0].truckQty.push({
                    truck: routePallet.registration_number,
                    quantity: item.quantity,
                  });
                }
              } else {
                let itmObj = {};
                itmObj.itemId = item.itemId;
                itmObj.itemName = item.itemName;
                itmObj.truckQty = [];
                itmObj.truckQty.push({
                  truck: routePallet.registration_number,
                  quantity: Number(item.quantity),
                });
                orderinformationRoutewise.push(itmObj);
              }
            }
          });
        });
      });
    });
    // console.log(orderinformationRoutewise);

    let truckId = 0;
    orderinformationRoutewise.map((item) => {
      let row = [];
      let totalQty = 0;
      item.truckQty.map((truckQty, trIdx) => {
        let row1 = [];
        if (trIdx == 0) {
          row1.push(item.itemId);
          row1.push(item.itemName);
        } else {
          row1.push("");
          row1.push("");
        }

        row1.push(truckQty.truck);
        row1.push(truckQty.quantity);

        rowD.push(row1);
        totalQty += Number(truckQty.quantity);
      });
      row.push("");
      row.push("Total");
      row.push("");
      row.push(totalQty);
      rowD.push(row);
    });

    routeNo = routeNo + 1;
    let formatted_date = moment(deliveryDate).format("DD-MMMM-YYYY");
    var pageContent = function (data) {
      // HEADER
      pdf.text(title, 250, startrow + 10);
      pdf.setFontSize(12);
      pdf.setLineWidth(1.0);
      pdf.text(" " + formatted_date + " ", 430, startrow + 25);
      pdf.line(5, startrow + 35, 590, startrow + 35);

      // FOOTER
      var str = "Page " + pageNo; //data.pageCount;
      // Total page number plugin only available in jspdf v1.0+
      if (typeof pdf.putTotalPages === "function") {
        str = str; // + " of " + totalPagesExp;
      }
      pdf.setFontSize(10);
      var pageHeight =
        pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
      pdf.text(str, data.settings.margin.left, pageHeight - 20); // showing current page number
      pageNo += 1;
    };

    pdf.autoTable(col, rowD, {
      didDrawPage: pageContent,
      theme: "grid",
      margin: {
        top: startrow + 45,
        left: 15,
      },
      willDrawCell: function (data) {
        if (data.section == "body") {
          if (data.row.raw[1] == "Total") {
            console.log(data.row.cells[1]);

            data.row.cells[1].styles.fontSize = "12";
            data.row.cells[1].styles.color = "#000";
            data.row.cells[1].styles.fontStyle = "bold";
            data.row.cells[1].styles.fillColor = [219, 219, 219];

            data.row.cells[2].styles.fontSize = "12";
            data.row.cells[2].styles.color = "#000";
            data.row.cells[2].styles.fontStyle = "bold";
            data.row.cells[2].styles.fillColor = [219, 219, 219];

            data.row.cells[3].styles.fontSize = "12";
            data.row.cells[3].styles.color = "#000";
            data.row.cells[3].styles.fontStyle = "bold";
            data.row.cells[3].styles.fillColor = [219, 219, 219];
          }
        }
      },
      styles: {
        fontSize: 8,
        valign: "middle",
        halign: "center",
        align: "center",
      },
      columnStyles: {
        0: { columnWidth: 60 },
        1: { columnWidth: 380 },
        2: { columnWidth: 60 },
        3: { columnWidth: 60 },
      },
    });

    if (routeNo < totalRoutes) {
      pdf.addPage();
    }

    //for adding total number of pages // i.e 10 etc
    if (typeof pdf.putTotalPages === "function") {
      pdf.putTotalPages(totalPagesExp);
    }
    pdf.save(moment(deliveryDate).format("DD-MM-YYYY") + "-Stock-By-Truck.pdf");
  };

  const printStockMovement = (type) => {
    let col = ["Item Code", "Description", "QTY"]; // initialization for headers
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var saleorderdate, transportrouteID;
    let title = "Stock Movement"; // title of report

    let pdf = new jsPDF("p", "pt", "A4");
    var startrow = 20;

    let rowD = [];
    const totalPagesExp = "{total_pages_count_string}";
    var ProductID = 0;
    var ProductQTY = 0;
    var truckQTY = 0;
    var truck;
    var totalRoutes = routePallets.length;

    var routeNo = 1;
    var pageNo = 1;

    var orderinformationRoutewise = [];

    setLoadMessage("");
    Auth.fetch(
      `/route/get-stock-movement?delivery_date=${moment(deliveryDate).format(
        "YYYY-MM-DD"
      )}&type=${type}`
    ).then((res) => {
      if (res.ack) {
        const items = res.items;

        // items.sort(function (a, b) {
        //     return a.itemId.localeCompare(b.itemId, 'en', { numeric: true });
        // });
        let rowsF = [];
        let rowsC = [];
        let rowsA = [];
        items.map((item) => {
          if (item.itemId.substring(0, 1) == "F") {
            rowsF.push(item);
          } else if (item.itemId.substring(0, 1) == "C") {
            rowsC.push(item);
          } else {
            rowsA.push(item);
          }
        });
        for (var idx = 0; idx <= 2; idx++) {
          let finalRows = [];
          rowD = [];
          let prodType = "";
          if (idx == 0) {
            finalRows = rowsA;
            prodType = " (Ambient)";
          } else if (idx == 1) {
            finalRows = rowsC;

            prodType = " (Chilled)";
          } else {
            finalRows = rowsF;
            prodType = " (Frozen)";
          }

          finalRows.map((item) => {
            let row = [];
            row.push(item.itemId);
            row.push(item.itemName);
            row.push(item.total_quantity);
            rowD.push(row);
          });
          routeNo = routeNo + 1;
          let formatted_date = moment(deliveryDate).format("DD-MMM-YYYY");
          var pageContent = function (data) {
            // HEADER
            pdf.text(title + prodType, 200, startrow + 10);
            pdf.setFontSize(12);
            pdf.setLineWidth(1.0);
            pdf.text(" " + formatted_date + " ", 500, startrow + 25);
            pdf.line(5, startrow + 35, 590, startrow + 35);

            // FOOTER
            var str = "Page " + pageNo; //data.pageCount;
            // Total page number plugin only available in jspdf v1.0+
            if (typeof pdf.putTotalPages === "function") {
              str = str; // + " of " + totalPagesExp;
            }
            pdf.setFontSize(10);
            var pageHeight =
              pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
            pdf.text(str, data.settings.margin.left, pageHeight - 20); // showing current page number
            pageNo += 1;
          };

          pdf.autoTable(col, rowD, {
            didDrawPage: pageContent,
            theme: "grid",
            margin: {
              top: startrow + 45,
              left: 15,
            },
            styles: {
              fontSize: 8,
              valign: "middle",
              halign: "center",
              align: "center",
            },
            columnStyles: {
              0: {
                columnWidth: 80,
                valign: "left",
                halign: "left",
                align: "left",
              },
              1: {
                columnWidth: 400,
                valign: "left",
                halign: "left",
                align: "left",
              },
              2: {
                columnWidth: 80,
                valign: "right",
                halign: "right",
                align: "right",
              },
            },
          });

          if (routeNo < totalRoutes) {
            pdf.addPage();
          }

          //for adding total number of pages // i.e 10 etc
          if (typeof pdf.putTotalPages === "function") {
            pdf.putTotalPages(totalPagesExp);
          }
          pdf.addPage();
        }
        pdf.save(
          moment(deliveryDate).format("DD-MM-YYYY") + "-Stock-movement.pdf"
        );
      } else {
        setType("error");
        setLoadMessage("No invoices found for Stock movement !!");
      }
    });

    // routePallets.map((routePallet) => {

    //     let route = routes.filter(r => r.id == routePallet.route_status_id);

    //     if (route.length == 0) {
    //         return;
    //     }
    //     else {
    //         routePallet.selectedRoute = route[0];
    //         routePallet.driver_name = route[0].driver_name;
    //         routePallet.registration_number = route[0].registration_number;
    //     }
    // });

    // routePallets.sort(function (a, b) {
    //     return a.driver_name.localeCompare(b.driver_name);
    // });

    // routePallets.map((routePallet) => {
    //     // console.log(routes)
    //     // console.log('Route ID:', routePallet.route_status_id)
    //     let sRoute = routes.filter(r => r.id == routePallet.route_status_id);
    //     // let routeSelected = {};
    //     // if (sRoute.length > 0)
    //     //     routeSelected = sRoute[0];

    //     // console.log(routeSelected)
    //     routePallet.pallets.map((pallet) => {

    //         pallet.items.map((order) => {

    //             order.items.map((item) => {
    //                 if (item.itemId != 'Delivery Charge') {
    //                     let checkItem = orderinformationRoutewise.filter(o => o.itemId == item.itemId);

    //                     if (checkItem.length > 0) {
    //                         // let checkTruck = checkItem[0].truckQty.filter(t => t.truck == routePallet.registration_number);
    //                         // if (checkTruck[0]) {
    //                         //     checkTruck[0].quantity += Number(item.quantity)
    //                         // }
    //                         // else {
    //                         checkItem[0].totalQty += Number(item.quantity);
    //                         // }
    //                     }
    //                     else {
    //                         let itmObj = {};
    //                         itmObj.itemId = item.itemId;
    //                         itmObj.itemName = item.itemName;
    //                         itmObj.totalQty = 0;
    //                         itmObj.totalQty = Number(item.quantity);
    //                         orderinformationRoutewise.push(itmObj)
    //                     }
    //                 }
    //             })
    //         })
    //     })
    // });
    // // console.log(orderinformationRoutewise);

    // let truckId = 0;
    // orderinformationRoutewise.map((item) => {
    //     let row = [];
    //     let totalQty = 0;
    //     // item.truckQty.map((truckQty, trIdx) => {

    //     //     let row1 = [];
    //     //     if (trIdx == 0) {
    //     //         row1.push(item.itemName);
    //     //     }
    //     //     else {
    //     //         row1.push("");
    //     //     }

    //     //     row1.push(truckQty.truck);
    //     //     row1.push(truckQty.quantity);

    //     //     rowD.push(row1);
    //     //     totalQty += Number(truckQty.quantity);
    //     // });
    //     row.push(item.itemName)
    //     row.push(item.totalQty);
    //     rowD.push(row)
    // });
  };

  const printStockByTruck = () => {
    let col = ["Item Code", "Description", "QTY"]; // , 'Truck No' // initialization for headers
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var saleorderdate, transportrouteID;
    let title =
      "Stock by Truck - " +
      selectedRoute.title +
      " (" +
      selectedRoute.registration_number +
      ")"; // title of report

    let pdf = new jsPDF("p", "pt", "A4");
    var startrow = 20;

    let rowD = [];
    const totalPagesExp = "{total_pages_count_string}";
    var ProductID = 0;
    var ProductQTY = 0;
    var truckQTY = 0;
    var truck;
    var totalRoutes = routePallets.length;

    var routeNo = 1;
    var pageNo = 1;

    var orderinformationRoutewise = [];

    // console.log(routes)
    // routePallets.map((routePallet) => {
    // console.log(routes)
    // console.log('Route ID:', routePallet.route_status_id)
    // let sRoute = routes.filter(r => r.id == routePallet.route_status_id);
    // let routeSelected = {};
    // if (sRoute.length > 0)
    //     routeSelected = sRoute[0];

    // console.log(pallets)

    let order_items = [];
    pallets.map((pallet) => {
      pallet.items.map((order) => {
        order.items.map((item) => {
          order_items.push(item);
        });
      });
    });
    // });
    order_items.sort(function (a, b) {
      return a.itemId.localeCompare(b.itemId, "en", { numeric: true });
    });

    // console.log(order_items)
    order_items.map((item) => {
      if (item.itemId != "Delivery Charge") {
        let checkItem = orderinformationRoutewise.filter(
          (o) => o.itemId == item.itemId
        );

        if (checkItem.length > 0) {
          let checkTruck = checkItem[0].truckQty.filter(
            (t) => t.truck == selectedRoute.registration_number
          );
          if (checkTruck[0]) {
            checkTruck[0].quantity += Number(item.quantity);
          } else {
            checkItem[0].truckQty.push({
              truck: selectedRoute.registration_number,
              quantity: item.quantity,
            });
          }
        } else {
          let itmObj = {};
          itmObj.itemId = item.itemId;
          itmObj.itemName = item.itemName;
          itmObj.truckQty = [];
          itmObj.truckQty.push({
            truck: selectedRoute.registration_number,
            quantity: Number(item.quantity),
          });
          orderinformationRoutewise.push(itmObj);
        }
      }
    });

    let truckId = 0;
    orderinformationRoutewise.map((item) => {
      let row = [];
      let totalQty = 0;
      item.truckQty.map((truckQty, trIdx) => {
        let row1 = [];
        if (trIdx == 0) {
          row1.push(item.itemId);
          row1.push(item.itemName);
        } else {
          row1.push("");
          row1.push("");
        }

        // row1.push(truckQty.truck);
        row1.push(truckQty.quantity);

        rowD.push(row1);
        totalQty += Number(truckQty.quantity);
      });
      // row.push("Total")
      // row.push("")
      // row.push(totalQty);
      // rowD.push(row)
    });
    // console.log(rowD);

    let rowsA = [];
    let rowsC = [];
    let rowsF = [];

    rowD.map((item) => {
      if (item[0].substring(0, 1) == "F") {
        rowsF.push(item);
      } else if (item[0].substring(0, 1) == "C") {
        rowsC.push(item);
      } else {
        rowsA.push(item);
      }
    });
    routeNo = routeNo + 1;
    for (var idx = 0; idx <= 2; idx++) {
      let finalRows = [];
      let prodType = "";
      if (idx == 0) {
        finalRows = rowsA;
        prodType = "(Ambient)";
      } else if (idx == 1) {
        finalRows = rowsC;

        prodType = "(Chilled)";
      } else {
        finalRows = rowsF;
        prodType = "(Frozen)";
      }

      let formatted_date = moment(deliveryDate).format("DD-MMM-YYYY");
      var pageContent = function (data) {
        // HEADER
        pdf.text(title, 150, startrow + 10);
        pdf.setFontSize(12);
        pdf.setLineWidth(1.0);
        pdf.text(prodType, 270, startrow + 25);
        pdf.text(" " + formatted_date + " ", 500, startrow + 25);
        pdf.line(5, startrow + 35, 590, startrow + 35);

        // FOOTER
        var str = "Page " + pageNo; // data.pageCount;
        // Total page number plugin only available in jspdf v1.0+
        if (typeof pdf.putTotalPages === "function") {
          str = str; // + " of " + totalPagesExp;
        }
        pdf.setFontSize(10);
        var pageHeight =
          pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
        pdf.text(str, data.settings.margin.left, pageHeight - 20); // showing current page number
        pageNo += 1;
      };

      pdf.autoTable(col, finalRows, {
        didDrawPage: pageContent,
        theme: "grid",
        margin: {
          top: startrow + 45,
          left: 15,
        },
        styles: {
          fontSize: 8,
          valign: "middle",
          halign: "center",
          align: "center",
        },
        columnStyles: {
          0: { columnWidth: 60 },
          1: { columnWidth: 440 },
          2: { columnWidth: 60 },
        },
      });
      //for adding total number of pages // i.e 10 etc
      if (typeof pdf.putTotalPages === "function") {
        pdf.putTotalPages(totalPagesExp);
      }
      pdf.addPage();
    }
    pdf.save(
      moment(deliveryDate).format("DD-MM-YYYY") +
        "-Stock-By-Truck-" +
        selectedRoute.title +
        " (" +
        selectedRoute.registration_number +
        ")" +
        ".pdf"
    );
  };

  const printFrozenStock = () => {
    let routePallets2 = JSON.parse(JSON.stringify(routePallets));
    let routePallets1 = [];
    routePallets2.map((rp2) => {
      rp2.pallets.map((pallet) => {
        pallet.items.map((order) => {
          order.items.map((item) => {
            if (item.frozenSheetItem == 1) {
              let check = routePallets1.filter(
                (rp1) => rp1.route_status_id == rp2.route_status_id
              );
              if (check.length == 0) routePallets1.push(rp2);
            }
          });
        });
      });
    });
    if (routePallets1.length == 0) {
      var totalRoutes = routePallets1.length;
      let rand = Math.random() * 50; // for unique message each time
      let str = " ";
      for (let i = 0; i < rand; i++) {
        str += " ";
      }
      setType("error");
      setLoadMessage("No frozen items in route" + str);
    } else {
      let col = ["Drop No", "Name", "Description", "QTY"]; // // initialization for headers

      let title = "Frozen Stock"; // title of report

      let pdf = new jsPDF("l", "pt", "a4");
      var startrow = 20;
      var totalRoutes = routePallets1.length;
      // console.log(totalRoutes)
      var routeNo = 1;
      var pageNo = 1;

      routePallets1.map((routePallet) => {
        let route = routes.filter((r) => r.id == routePallet.route_status_id);

        if (route.length == 0) {
          return;
        }
        let selectedRoute1 = route[0];
        let rows = [];
        var SeqNo = 0;
        var total_weight = 0.0;
        var previousTotalPages = 0;
        const totalPagesExp = "{total_pages_count_string}";
        let pallets1 = JSON.parse(JSON.stringify(routePallet.pallets));
        // orderinformationRoutewise[x].sort((a, b) => (a.saleorder_ID.transportroute_SEQUENCENO < b.saleorder_ID.transportroute_SEQUENCENO) ? 1 : -1);
        pallets1.map((pallet) => {
          pallet.items.map((order) => {
            order.items.map((item) => {
              if (
                item.itemId != "Delivery Charge" &&
                item.frozenSheetItem == 1
              ) {
                let row = [];
                if (SeqNo == 0 || SeqNo != order.rank) {
                  row.push(order.rank);
                  row.push(order.customerProjectId);
                } else {
                  row.push("");
                  row.push("");
                }
                SeqNo = order.rank;
                row.push(item.itemName);
                row.push(item.quantity);
                rows.push(row);
              }
            });
          });
        });

        let formatted_date = moment(deliveryDate).format("DD-MMMM-YYYY");
        var truck = selectedRoute1.registration_number;
        var driver = selectedRoute1.driver_name;

        var pageContent = function (data) {
          // HEADER
          pdf.setFontSize(14);

          pdf.text(title, 50, startrow + 10);
          pdf.text(formatted_date, 600, startrow + 10);

          pdf.text("Driver : " + driver, 50, startrow + 10 + 20);

          pdf.text("Truck: " + truck, 600, startrow + 30);
          pdf.text(
            "Route Description: " +
              selectedRoute1.title +
              " (" +
              selectedRoute1.code +
              ")",
            300,
            startrow + 10
          );

          //side Box
          pdf.setLineWidth(1.5);
          //horizontal lines
          pdf.line(550, startrow + 60, 820, startrow + 60);
          pdf.line(550, startrow + 250, 820, startrow + 250);
          pdf.line(550, startrow + 360, 820, startrow + 360);
          pdf.line(550, startrow + 550, 820, startrow + 550);
          //vertical lines
          pdf.line(550, startrow + 60, 550, startrow + 550);
          pdf.line(820, startrow + 60, 820, startrow + 550);
          //inside vertical box
          //Driver Checklist
          pdf.setFontSize(13);
          pdf.text("DRIVER CHECKLIST", 610, startrow + 80);
          pdf.setFontSize(10);
          //checkboxes
          pdf.setLineWidth(1.0);
          //checkbox1
          pdf.line(580, startrow + 93, 590, startrow + 93);
          pdf.line(580, startrow + 103, 590, startrow + 103);
          pdf.line(580, startrow + 93, 580, startrow + 103);
          pdf.line(590, startrow + 93, 590, startrow + 103);
          //checkbox2
          pdf.line(580, startrow + 108, 590, startrow + 108);
          pdf.line(580, startrow + 118, 590, startrow + 118);
          pdf.line(580, startrow + 108, 580, startrow + 118);
          pdf.line(590, startrow + 108, 590, startrow + 118);
          //checkbox3
          pdf.line(580, startrow + 123, 590, startrow + 123);
          pdf.line(580, startrow + 133, 590, startrow + 133);
          pdf.line(580, startrow + 123, 580, startrow + 133);
          pdf.line(590, startrow + 123, 590, startrow + 133);
          //checkbox4
          pdf.line(580, startrow + 138, 590, startrow + 138);
          pdf.line(580, startrow + 148, 590, startrow + 148);
          pdf.line(580, startrow + 138, 580, startrow + 148);
          pdf.line(590, startrow + 138, 590, startrow + 148);
          //checkbox5
          pdf.line(580, startrow + 153, 590, startrow + 153);
          pdf.line(580, startrow + 163, 590, startrow + 163);
          pdf.line(580, startrow + 153, 580, startrow + 163);
          pdf.line(590, startrow + 153, 590, startrow + 163);
          //checkbox6
          pdf.line(580, startrow + 168, 590, startrow + 168);
          pdf.line(580, startrow + 178, 590, startrow + 178);
          pdf.line(580, startrow + 168, 580, startrow + 178);
          pdf.line(590, startrow + 168, 590, startrow + 178);

          //text
          pdf.text("TRUCK KEY RECIEVED", 610, startrow + 100);
          pdf.text("VEHICLE CHECKED", 610, startrow + 115);
          pdf.text("FUEL CARD PRESENT", 610, startrow + 130);
          pdf.text("SAFE AND KEY PRESENT", 610, startrow + 145);
          pdf.text("NUMBER OF TROLLEYS.....", 610, startrow + 160);
          pdf.text("NUMBER OF PUMPTRUCKS.....", 610, startrow + 175);
          //Name Signatures
          pdf.text(
            ".....................................",
            560,
            startrow + 190
          );
          pdf.text(
            ".....................................",
            700,
            startrow + 190
          );
          pdf.text("NAME", 597, startrow + 200);
          pdf.text("SIGNATURE", 721, startrow + 200);
          pdf.setFontSize(9);
          pdf.text(
            "By signing I declare that I am fit to work on the date stated",
            560,
            startrow + 210
          );
          pdf.text("on this sheet. ", 560, startrow + 220);
          pdf.text(
            "I am not under the influence of drugs and/or alcohol.",
            560,
            startrow + 230
          );
          //Mechanic Checklist
          //checkbox1
          pdf.line(580, startrow + 280, 590, startrow + 280);
          pdf.line(580, startrow + 290, 590, startrow + 290);
          pdf.line(580, startrow + 280, 580, startrow + 290);
          pdf.line(590, startrow + 280, 590, startrow + 290);
          //checkbox2
          pdf.line(580, startrow + 295, 590, startrow + 295);
          pdf.line(580, startrow + 305, 590, startrow + 305);
          pdf.line(580, startrow + 295, 580, startrow + 305);
          pdf.line(590, startrow + 295, 590, startrow + 305);

          //text
          pdf.setFontSize(13);
          pdf.text("MECHANIC CHECKLIST", 610, startrow + 265);
          pdf.setFontSize(10);
          pdf.text("PMI COMPLETED", 610, startrow + 285);
          pdf.text("VEHICLE GOOD TO DRIVE", 610, startrow + 300);
          //Name and Signature
          pdf.text(
            ".....................................",
            560,
            startrow + 320
          );
          pdf.text(
            ".....................................",
            700,
            startrow + 320
          );
          pdf.text("NAME", 597, startrow + 330);
          pdf.text("SIGNATURE", 721, startrow + 330);
          //Warehouse Checklist
          //checkbox1
          pdf.line(580, startrow + 393, 590, startrow + 393);
          pdf.line(580, startrow + 403, 590, startrow + 403);
          pdf.line(580, startrow + 393, 580, startrow + 403);
          pdf.line(590, startrow + 393, 590, startrow + 403);
          //checkbox2
          pdf.line(580, startrow + 408, 590, startrow + 408);
          pdf.line(580, startrow + 418, 590, startrow + 418);
          pdf.line(580, startrow + 408, 580, startrow + 418);
          pdf.line(590, startrow + 408, 590, startrow + 418);
          //checkbox3
          pdf.line(580, startrow + 423, 590, startrow + 423);
          pdf.line(580, startrow + 433, 590, startrow + 433);
          pdf.line(580, startrow + 423, 580, startrow + 433);
          pdf.line(590, startrow + 423, 590, startrow + 433);
          //checkbox4
          pdf.line(580, startrow + 453, 590, startrow + 453);
          pdf.line(580, startrow + 463, 590, startrow + 463);
          pdf.line(580, startrow + 453, 580, startrow + 463);
          pdf.line(590, startrow + 453, 590, startrow + 463);
          //checkbox5
          pdf.line(580, startrow + 468, 590, startrow + 468);
          pdf.line(580, startrow + 478, 590, startrow + 478);
          pdf.line(580, startrow + 468, 580, startrow + 478);
          pdf.line(590, startrow + 468, 590, startrow + 478);
          //checkbox6
          pdf.line(580, startrow + 483, 590, startrow + 483);
          pdf.line(580, startrow + 493, 590, startrow + 493);
          pdf.line(580, startrow + 483, 580, startrow + 493);
          pdf.line(590, startrow + 483, 590, startrow + 493);
          //checkbox7
          pdf.line(580, startrow + 498, 590, startrow + 498);
          pdf.line(580, startrow + 508, 590, startrow + 508);
          pdf.line(580, startrow + 498, 580, startrow + 508);
          pdf.line(590, startrow + 498, 590, startrow + 508);
          pdf.setFontSize(13);
          pdf.text("WAREHOUSE CHECKLIST", 610, startrow + 380);
          pdf.setFontSize(10);
          pdf.text("MECHANIC SIGNED", 610, startrow + 400);
          pdf.text("DRIVER SIGNED", 610, startrow + 415);

          pdf.text("INVOICES,FINANCIAL SHEETS,ROUTE", 610, startrow + 430);
          pdf.text("SHEETS GIVEN TO DRIVER", 610, startrow + 445);
          pdf.text(
            "STARTING MILEAGE................................",
            610,
            startrow + 460
          );
          pdf.text("FUEL LEVEL 0 25% 50% 75% 100%", 610, startrow + 475);
          pdf.text(
            "END MILEAGE.....................................",
            610,
            startrow + 490
          );
          pdf.text("FUEL LEVEL 0 25% 50% 75% 100%", 610, startrow + 505);
          //Name and Signature
          pdf.text(
            ".....................................",
            560,
            startrow + 520
          );
          pdf.text(
            ".....................................",
            700,
            startrow + 520
          );
          pdf.text("NAME", 597, startrow + 530);
          pdf.text("SIGNATURE", 721, startrow + 530);

          //bottom Box
          pdf.line(40, startrow + 490, 530, startrow + 490); //h1
          pdf.line(40, startrow + 550, 530, startrow + 550); //h2
          pdf.line(40, startrow + 490, 40, startrow + 550); //v1
          pdf.line(530, startrow + 490, 530, startrow + 550); //v2

          //text
          pdf.setFontSize(11);
          pdf.text("ICE CREAM CHECKLIST", 210, startrow + 500);
          pdf.setLineWidth(1.5);
          pdf.line(210, startrow + 502, 350, startrow + 502);

          pdf.setFontSize(8);
          pdf.text("ADD ONS & ICE CREAM LOADED", 70, startrow + 517);
          pdf.text(
            "ADD ONS ADDED TO FINANCIAL SHEETSB (INCLUDING INVOICES)",
            270,
            startrow + 517
          );
          pdf.setLineWidth(1);
          pdf.text(
            "..................................................",
            300,
            startrow + 535
          );
          pdf.text("SIGNATURE", 340, startrow + 545);

          pdf.text(
            "..................................................",
            60,
            startrow + 535
          );
          pdf.text("NAME", 100, startrow + 545);

          //checkbox1
          pdf.setLineWidth(0.8);
          pdf.line(50, startrow + 510, 60, startrow + 510); //h1
          pdf.line(50, startrow + 520, 60, startrow + 520); //h2
          pdf.line(50, startrow + 510, 50, startrow + 520); //v1
          pdf.line(60, startrow + 510, 60, startrow + 520); //v2

          //checkbox2
          pdf.setLineWidth(0.8);
          pdf.line(250, startrow + 510, 260, startrow + 510); //h1
          pdf.line(250, startrow + 520, 260, startrow + 520); //h2
          pdf.line(250, startrow + 510, 250, startrow + 520); //v1
          pdf.line(260, startrow + 510, 260, startrow + 520); //v2

          // FOOTER
          var str = "Page " + pageNo; //data.pageCount;
          // Total page number plugin only available in jspdf v1.0+
          if (typeof pdf.putTotalPages === "function") {
            str = str; // + " of " + totalPagesExp;
          }
          pdf.setFontSize(10);
          var pageHeight =
            pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
          pdf.text(str, data.settings.margin.left, pageHeight - 20); // showing current page number
          pageNo += 1;
        };

        pdf.autoTable(col, rows, {
          didDrawPage: pageContent,
          theme: "grid",
          margin: {
            top: startrow + 60,
            bottom: startrow + 90,
          },
          styles: {
            fontSize: 8,
            valign: "middle",
            halign: "center",
            align: "center",
          },
          columnStyles: {
            0: { columnWidth: 60 },
            1: { columnWidth: 180 },
            2: {
              columnWidth: 210,
              valign: "left",
              halign: "left",
              align: "left",
            },
            3: {
              columnWidth: 40,
              valign: "left",
              halign: "left",
              align: "left",
            },
          },
        });

        //for adding total number of pages // i.e 10 etc
        if (typeof pdf.putTotalPages === "function") {
          pdf.putTotalPages(totalPagesExp);
        }
        if (routeNo < totalRoutes) {
          pdf.addPage();
        }

        routeNo = routeNo + 1;
      });
      pdf.save(moment(deliveryDate).format("DD-MM-YYYY") + "-Frozen-Sheet.pdf");
    }
  };

  const printAll = () => {
    printPalletSheet();
    printDriverManifest();
    printDriverSheet();
    printGoodsReturnReport();
    printPickListReport();
    printStockByTruck();
    printCheckListReport();
  };

  return (
    <>
      <div>
      <h2 style={{marginBottom: '10px', marginTop: 0}}>Print Reports</h2>
        <Grid
          container
          spacing={2}
          sx={{ alignItems: "flex-end", display: "flex" }}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              {/* <DatePicker
                value={deliveryDate}
                format={"dd-MM-yyyy"}
                onChange={(date) => {
                  setDeliveryDate(date);
                  setRoutes([]);
                  setSelectedRoute([]);
                }}
                className={classes.dateField}
              /> */}
              <AntDate
               format={'YYYY-MM-DD'}
               style={{ width: 160}}
               size="large"
               value={deliveryDate ? dayjs(deliveryDate) : null}
               onChange={(_, dateStr) => {
                setDeliveryDate(dateStr);
                setRoutes([]);
                setSelectedRoute([]);
              }}
              placeholder="Select date" 
               />
              <AntButton type="primary" size="large" onClick={loadRoutes} >Load Routes</AntButton>
              {/* <FormButton type="noIcon" onClick={loadRoutes}>
                Load Routes
              </FormButton> */}
            </Box>
          </Grid>
        </Grid>

        <br />
        {/* <Grid container spacing={1}>
          <Grid item xs={12} lg={6.8}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              <FormButton
                type="print"
                className={classes.btn}
                onClick={(e) => {
                  printStockMovement(1);
                }}
              >
                {" "}
                Stock Movement (Sales Order)
              </FormButton>
              <FormButton
                type="print"
                className={classes.btn}
                onClick={(e) => {
                  printStockMovement(2);
                }}
              >
                {" "}
                Stock Movement (Sales Invoice)
              </FormButton>
            </Box>
          </Grid>
          <Grid item xs={12} lg={5.2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              <FormButton
                type="print"
                className={classes.btn}
                style={{ float: "right" }}
                onClick={(e) => {
                  printCollectionOrders();
                }}
              >
                {" "}
                Collection Orders{" "}
              </FormButton>
              <FormButton
                type="print"
                className={classes.btn}
                style={{ float: "right" }}
                onClick={(e) => {
                  printCollectionOrdersSummary();
                }}
              >
                {" "}
                Collection Orders Summary{" "}
              </FormButton>
            </Box>
          </Grid>
        </Grid> */}

        <div style={{display: "flex", flexDirection: "column", gap: "8px"}}>
          <div style={{display: "flex", alignItems: "center", flexWrap: "wrap", gap: "8px"}}>
            <AntButton
                type="primary"
                size="large"
                icon= {<PrinterFilled />}
                onClick={(e) => {
                  printStockMovement(1);
                }}
              >
                {" "}
                Stock Movement (Sales Order)
              </AntButton>
              <AntButton
                type="primary"
                size="large"
                icon= {<PrinterFilled />}
                onClick={(e) => {
                  printStockMovement(2);
                }}
              >
                {" "}
                Stock Movement (Sales Invoice)
              </AntButton>
              <AntButton
                type="primary"
                size="large"
                icon= {<PrinterFilled />}
                onClick={(e) => {
                  printCollectionOrdersSummary();
                }}
              >
                {" "}
                Collection Orders Summary{" "}
              </AntButton>
              <AntButton
                type="primary"
                size="large"
                icon= {<PrinterFilled />}
                onClick={(e) => {
                  printCollectionOrders();
                }}
              >
                {" "}
                Collection Orders{" "}
              </AntButton>
  </div>
</div>
        


        <br />
        {/* <Grid container spacing={2}>
          <Grid item xl={2.5} lg={2.5}>
            <FormButton
              type="print"
              className={classes.btn}
              onClick={(e) => {
                printStockMovement(1);
              }}
            >
              {" "}
              Stock Movement (Sales Order)
            </FormButton>
          </Grid>
          <Grid item xl={2.5} lg={3}>
            <FormButton
              type="print"
              className={classes.btn}
              onClick={(e) => {
                printStockMovement(2);
              }}
            >
              {" "}
              Stock Movement (Sales Invoice)
            </FormButton>
          </Grid>
          <Grid item xl={4.5} lg={4}>
            <FormButton
              type="print"
              className={classes.btn}
              style={{ float: "right" }}
              onClick={(e) => {
                printCollectionOrders();
              }}
            >
              {" "}
              Collection Orders{" "}
            </FormButton>
          </Grid>
          <Grid item xl={2.5} lg={2.5}>
            <FormButton
              type="print"
              className={classes.btn}
              style={{ float: "right" }}
              onClick={(e) => {
                printCollectionOrdersSummary();
              }}
            >
              {" "}
              Collection Orders Summary{" "}
            </FormButton>
          </Grid>
        </Grid> */}
        <hr />
        {routes.length > 0 && (
          <div>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                {/* <FormButton type="print" className={classes.btn} onClick={(e) => { printFinancialSummary() }} > Financial Summary</FormButton>*/}
                {/* <FormButton
                  type="print"
                  className={classes.btn}
                  onClick={(e) => {
                    printFinancialSummaryExcel();
                  }}
                >
                  {" "}
                  Financial Summary
                </FormButton> */}
                <AntButton
                type="primary"
                size="large"
                icon= {<PrinterFilled />}
                onClick={(e) => {
                  printFinancialSummaryExcel();
                }}
              >
                Financial Summary
              </AntButton>
              <AntButton
                type="primary"
                size="large"
                icon= {<PrinterFilled />}
                onClick={(e) => {
                  printDriverManifest();
                }}
              >
                Driver Manifest
              </AntButton>
                {/* <FormButton
                  type="print"
                  onClick={(e) => {
                    printDriverManifest();
                  }}
                >
                  {" "}
                  Driver Manifest
                </FormButton> */}
                {/* <FormButton
                  type="print"
                  onClick={(e) => {
                    printDriverSheet();
                  }}
                >
                  {" "}
                  Driver Sheet
                </FormButton> */}
                <AntButton
                type="primary"
                size="large"
                icon= {<PrinterFilled />}
                onClick={(e) => {
                  printDriverSheet();
                }}
              >
                Driver Sheet
              </AntButton>
              <AntButton
                type="primary"
                size="large"
                icon= {<PrinterFilled />}
                onClick={(e) => {
                  printGoodsReturnReport();
                }}
              >
                Goods Return
              </AntButton>
                {/* <FormButton
                  type="print"
                  onClick={(e) => {
                    printGoodsReturnReport();
                  }}
                >
                  {" "}
                  Goods return
                </FormButton> */}
                {/* <FormButton type="print" onClick={printCheckListReport}>
                  {" "}
                  Check List
                </FormButton> */}
                <AntButton
                type="primary"
                size="large"
                icon= {<PrinterFilled />}
                onClick={(e) => {
                  printCheckListReport();
                }}
              >
                 Check List
              </AntButton>
              <AntButton
                type="primary"
                size="large"
                icon= {<PrinterFilled />}
                onClick={(e) => {
                  printFrozenStock();
                }}
              >
                 Frozen Stock
              </AntButton>
                {/* <FormButton type="print" onClick={(e) => { printPickListReport() }} > Pick List</FormButton> */}
                {/* <FormButton
                  type="print"
                  onClick={(e) => {
                    printFrozenStock();
                  }}
                >
                  {" "}
                  Frozen Stock
                </FormButton> */}
                {/* <FormButton
                  type="print"
                  onClick={(e) => {
                    printRoutePickingAndPacking();
                  }}
                >
                  {" "}
                  Picking & Packing
                </FormButton> */}
                <AntButton
                type="primary"
                size="large"
                icon= {<PrinterFilled />}
                onClick={(e) => {
                  printRoutePickingAndPacking();
                }}
              >
                 Picking & Packing
              </AntButton>

                {/* <FormButton type="print" onClick={(e) => { printStockByTruckAllRoutes() }} > All Routes Stock By Truck</FormButton> */}
                {/* <React.Fragment className={classes.btn}>
                                    <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                                        <Button onClick={selectedIndex ? printFinancialSummary : printFinancialSummaryExcel}>{options[selectedIndex]} </Button>
                                        <Button
                                            size="small"
                                            aria-controls={open ? 'split-button-menu' : undefined}
                                            aria-expanded={open ? 'true' : undefined}
                                            aria-label="Financial Summary"
                                            aria-haspopup="menu"
                                            onClick={handleToggle}
                                        >
                                            <ArrowDropDownIcon />
                                        </Button>
                                    </ButtonGroup>
                                    <Popper
                                        sx={{
                                            zIndex: 1,
                                        }}
                                        open={open}
                                        anchorEl={anchorRef.current}
                                        role={undefined}
                                        transition
                                        disablePortal
                                    >
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{
                                                    transformOrigin:
                                                        placement === 'bottom' ? 'center top' : 'center bottom',
                                                }}
                                            >
                                                <Paper>
                                                    <ClickAwayListener onClickAway={handleClose}>
                                                        <MenuList id="split-button-menu" autoFocusItem>
                                                            {options.map((option, index) => (
                                                                <MenuItem
                                                                    key={option}
                                                                    disabled={index === 2}
                                                                    selected={index === selectedIndex}
                                                                    onClick={(event) => handleMenuItemClick(event, index)}
                                                                >
                                                                    {option}
                                                                </MenuItem>
                                                            ))}
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>
                                </React.Fragment> */}
                {/* <FormButton type="print" className={classes.btn} onClick={(e) => { printStockByShop() }} disabled={true}> Stock By Shop</FormButton> */}
                {/* <Button variant="contained" className={classes.btn} onClick={(e) => { printFinancialSummary() }} endIcon={<Print />}> Financial Summary</Button>
                            <Button variant="contained" className={classes.btn} onClick={(e) => { printStockByShop() }} endIcon={<Print />} disabled={true}> Stock By Shop</Button>
                            <Button variant="contained" className={classes.btn} onClick={(e) => { printStockByTruckAllRoutes() }} endIcon={<Print />}> All Routes Stock By Truck</Button> */}
              </Grid>
            </Grid>
            <hr />
          </div>
        )}

        {routes.length > 0 && (
          <>
            <div>
              <p>Route Reports</p>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={3}>
              <Autocomplete
            id="route"
            fullWidth
            size="large"
            options={routes}
            getOptionLabel={(option) => option.title ? `${option.title} (${option.code}) - ${option.registration_number}` : ''}
            onChange={(e, values) => {
              loadRoutePallets(values?.id)

            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select completed route"
                label="Select completed route"
              />
            )}
          />
                {/* <FormControl fullWidth>
                  <InputLabel id="completed-route-label">
                    Select completed route
                  </InputLabel>
                  <Select
                    labelId="completed-route-label"
                    id="completed-route"
                    style={{ marginBottom: "5px" }}
                    label="Select completed route"
                    onChange={(e) => {
                      loadRoutePallets(e.target.value);
                    }}
                  >
                    <MenuItem value={0}>{"Select Route"}</MenuItem>
                    {routes.map((r, index) => {
                      return (
                        <MenuItem value={r.id} key={index}>
                          {r.title +
                            " (" +
                            r.code +
                            ") - " +
                            r.registration_number}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl> */}
              </Grid>
              <Grid item xs={2}>
                {selectedRoute.id && (
                  <p>
                    Vehicle: <b>{selectedRoute.registration_number}</b>{" "}
                  </p>
                )}
              </Grid>
              <Grid item xs={2}>
                {selectedRoute.id && (
                  <p>
                    Driver: <b>{selectedRoute.driver_name}</b>{" "}
                  </p>
                )}
              </Grid>
            </Grid>
          </>
        )}
        <br />
        {selectedRoute.id > 0 && (
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {/* <FormButton type="print" onClick={(e) => { printAll() }} > Print All Reports</FormButton> */}

                <FormButton
                  type="print"
                  onClick={(e) => {
                    printPalletSheet();
                  }}
                >
                  {" "}
                  Pallet Sheet
                </FormButton>
                <FormButton
                  type="print"
                  onClick={(e) => {
                    printStockByTruck();
                  }}
                >
                  {" "}
                  Stock by Truck
                </FormButton>

                {/* <Button variant="contained" className={classes.btn} onClick={(e) => { printPalletSheet() }} endIcon={<Print />}> Pallet Sheet</Button>
                            <Button variant="contained" className={classes.btn} onClick={(e) => { printDriverManifest() }} endIcon={<Print />}> Driver Manifest</Button>
                            <Button variant="contained" className={classes.btn} onClick={(e) => { printDriverSheet() }} endIcon={<Print />}> Driver Sheet</Button>
                            <Button variant="contained" className={classes.btn} onClick={(e) => { printGoodsReturnReport() }} endIcon={<Print />}> Goods return</Button>
                            <Button variant="contained" className={classes.btn} onClick={(e) => { printPickListReport() }} endIcon={<Print />}> Pick List</Button>
                            <Button variant="contained" className={classes.btn} onClick={(e) => { printStockByTruck() }} endIcon={<Print />}> Stock by Truck</Button> */}
              </Grid>
            </Grid>
            <hr />
          </div>
        )}
        <Message type={type} msg={loadMessage} />
      </div>
    </>
  );
}
